import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlUtils } from '../../../shared/utils/url.utils';
import { RestApiBase } from '../../../shared/configurations/rest-api-base.config';

@Injectable()
export class DepositDoiService {
  institutionCode: string;

  constructor(private http: HttpClient) {
    this.institutionCode = UrlUtils.getParam('institution');
  }

  haveReservedDoiButton(assetType) {
    let params = new HttpParams();
    params = params.set('institution', this.institutionCode).set('assetType', assetType);
    return this.http.get(RestApiBase.HAVE_RESERVE_DOI_BUTTON, {params: params});
  }

  getNewReserveDoi(assetType) {
    let params = new HttpParams();
    params = params.set('institution', this.institutionCode).set('assetType', assetType);
    return this.http.get(RestApiBase.GET_NEW_RESERVE_DOI, {params: params});
  }

}
