import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { A11yModule } from '@angular/cdk/a11y';
import { MediaIfDirective, SvgViewboxDirective } from '@exl-ng/mulo-core';
import {
    ActionListItemActionComponent,
    ActionListItemComponent,
    ContextFrameComponent,
    DragDirective,
    DropListDirective,
    ElementAsHeadingDirective,
    HtmlEscapePipe,
    HtmlSanitizePipe,
    HtmlTrustPipe,
    InputExpanderDirective,
    MultipleEventsDirective,
} from '@exl-ng/mulo-common';

import { MaterialBaseModule } from '../../material/material-base.module';
import { EsploroMaterialModule } from '../../material/apps-modules/esploro-material.module';

import { HighlightFilterPipe } from './pipes/highlight-filter.pipe';
import { OnboardingDirective } from './directives/onboarding/onboarding.directive';
import { FocusDirective } from './directives/focus/focus.directive';
import { GroupByPipe } from './pipes/group-by.pipe';
import { IntervalerDirective } from './directives/intervaler/intervaler.directive';
import { CustomTranslatePipe } from './pipes/custom-translate.pipe';
import { DepositFormTriggerDirective } from '../deposit/deposit-form-trigger.directive';
import {
    FramedFormComponent,
    FramedFormContentDirective,
} from './directives/framed-form/framed-form.directive';
import { TransitionGroupItemDirective } from './directives/transition-group/transition-group.directive';
import { VerticalTabsDirective } from './directives/vertical-tabs/vertical-tabs.directive';
import { DomChangeDirective } from './directives/dom-changes/dom-changes.directive';
import { SafeHtml } from './pipes/safeHTML.pipe';
import { DefaultTranslatePipe } from './pipes/default-translate.pipe';
import { RequiredInputDirective } from './directives/input-required/input-required.directive';
import { AutomationClassDirective } from './directives/tests/automation-tests-class.directive';
import { DescriptionDirective } from './directives/a11y/description/exl-description.directive';
import { A11yEspModule } from './directives/a11y/a11y-esp.module';
import { ConcatPipe } from './pipes/concat.pipe';
import { FocusInvalidFormFieldDirective } from './directives/focus/focus-invalid-form-field.directive';
import { ErrorsTranslatePipe } from './pipes/errors-translate.pipe';
import { TranslateByTypePipe } from './pipes/translate-by-type.pipe';
import { EspDateFormatPipe } from './pipes/date-format.pipe';
import { EspInitialsPipe } from './pipes/initials.pipe';
import { AutofocusDirective } from './directives/focus/auto-focus';
import { GeneralPageAltImagePipe } from './pipes/general-page-alt-image.pipe';
import { DisableByConfigurationPipe } from './pipes/disable-by-configuration.pipe';
import { HideFieldDirective } from './directives/lock-field-directives/hide-field.directive';
import { ResearcherTitlePipe } from './pipes/researcher-title.pipe';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { TranslateObjectPipe } from './pipes/translate-object.pipe';

//do NOT put providers here
@NgModule({
    imports: [
        A11yModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MaterialBaseModule,
        EsploroMaterialModule,
        MediaIfDirective,
        InputExpanderDirective,
        SvgViewboxDirective,
        HtmlEscapePipe,
        HtmlTrustPipe,
        MultipleEventsDirective,
        ElementAsHeadingDirective,
        HtmlSanitizePipe,
        ActionListItemComponent,
        ActionListItemActionComponent,
        DragDirective,
        DropListDirective,
        ContextFrameComponent,
    ],
    declarations: [
        HighlightFilterPipe,
        GroupByPipe,
        CustomTranslatePipe,
        DefaultTranslatePipe,
        GeneralPageAltImagePipe,
        OnboardingDirective,
        FocusDirective,
        IntervalerDirective,
        DepositFormTriggerDirective,
        CustomTranslatePipe,
        TranslateByTypePipe,
        ErrorsTranslatePipe,
        TranslateObjectPipe,
        ConcatPipe,
        EspDateFormatPipe,
        EspInitialsPipe,
        SafeHtml,
        ResearcherTitlePipe,
        FilterListPipe,
        FramedFormComponent,
        RequiredInputDirective,
        FramedFormContentDirective,
        TransitionGroupItemDirective,
        VerticalTabsDirective,
        DomChangeDirective,
        FocusInvalidFormFieldDirective,
        DescriptionDirective,
        AutomationClassDirective,
        AutofocusDirective,
        HideFieldDirective,
        DisableByConfigurationPipe,
    ],
    providers: [],
    exports: [
        A11yModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MaterialBaseModule,
        EsploroMaterialModule,
        HighlightFilterPipe,
        GroupByPipe,
        CustomTranslatePipe,
        TranslateByTypePipe,
        ErrorsTranslatePipe,
        TranslateObjectPipe,
        FocusInvalidFormFieldDirective,
        ConcatPipe,
        EspDateFormatPipe,
        EspInitialsPipe,
        FilterListPipe,
        SafeHtml,
        ResearcherTitlePipe,
        OnboardingDirective,
        FocusDirective,
        IntervalerDirective,
        InputExpanderDirective,
        DepositFormTriggerDirective,
        CustomTranslatePipe,
        DefaultTranslatePipe,
        GeneralPageAltImagePipe,
        FramedFormComponent,
        RequiredInputDirective,
        FramedFormContentDirective,
        TransitionGroupItemDirective,
        VerticalTabsDirective,
        ElementAsHeadingDirective,
        DomChangeDirective,
        MultipleEventsDirective,
        DescriptionDirective,
        AutomationClassDirective,
        A11yEspModule,
        AutofocusDirective,
        MediaIfDirective,
        HideFieldDirective,
        DisableByConfigurationPipe,
        SvgViewboxDirective,
        HtmlEscapePipe,
        HtmlTrustPipe,
        HtmlSanitizePipe,
        ActionListItemComponent,
        ActionListItemActionComponent,
        DragDirective,
        DropListDirective,
        ContextFrameComponent,
    ],
})
export class SharedModule {}
