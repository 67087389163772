<mulo-context-frame
    (close)="handleFormClose()"
    [isOpen]="enterNewPerson"
    [closeBtnAriaLabel]="'research.aria.deposit.authors.add.close' | translate"
>
    <esp-new-author
        (newPerson)="addAuthor($event)"
        *ngIf="enterNewPerson"
        @growInAnimation
        @growOutAnimation
        [(enterNewPerson)]="enterNewPerson"
        [newAuthorType]="newAuthorType"
    >
    </esp-new-author>
    <div slot="closed-view" class="author-search-form">
        <form>
            <mat-form-field class="is-comfortably-readable">
                <mat-label>{{ 'research.people.overline' | customTranslate }}</mat-label>
                <input
                    #searchInput
                    (focus)="onSearchInputFocus()"
                    [formControl]="authorCtrl"
                    [matAutocomplete]="auto"
                    matInput
                />
                <mat-progress-bar *ngIf="searchInProgress" color="primary" mode="indeterminate"></mat-progress-bar>
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
                    <mat-option
                        (onSelectionChange)="onAuthorSelect(author)"
                        *ngFor="let author of creatorsAndContributors"
                        [disabled]="searchInProgress"
                        [value]="author.name"
                    >
                        <span [innerHTML]="author.name | highlight : authorCtrl.value"></span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
        <span class="add-btns">
            <span class="margin-left-sm margin-right-xs">{{ 'research.people.or' | customTranslate }}</span>
            <button
                #newPersonBtn
                (click)="toggleNewAuthorForm('person', newPersonBtn)"
                color="primary"
                mat-button
                type="button"
            >
                {{ 'research.people.subtitle' | customTranslate }}
            </button>
            <ng-container *ngIf="isOrganizationButtonDisplay">
                <span class="margin-left-xs margin-right-xs">{{ 'research.people.or' | customTranslate }}</span>
                <button
                    #newOrgBtn
                    (click)="toggleNewAuthorForm('organization', newOrgBtn)"
                    color="primary"
                    mat-button
                    type="button"
                >
                    {{ 'research.organization.subtitle' | customTranslate }}
                </button>
            </ng-container>
        </span>
    </div>
</mulo-context-frame>

<esp-authors-list *ngIf="authors?.value.length" [authors]="authors" class="margin-top-xs"></esp-authors-list>
