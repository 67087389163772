import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Observable, map, tap, finalize } from 'rxjs';
import { MediaQueryAlias, aliasMap } from '../models/media-query-alias.model';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private _document?: Document;
  private renderer: Renderer2;
  private _currentlyActive: MediaQueryAlias[];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private rendererFactory: RendererFactory2,
    // Optimally renderer2 should not be injected in a service. But we make an exception here. To make it work without getting NullInjectorError, we provide rendererFactory and implementat in the constructor below
    @Inject(DOCUMENT) private document?: any // type 'any' is used here in stead of 'Ducument' https://github.com/angular/angular/issues/20351
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this._document = document as Document;
  }

  private setDocumentMediaClasses(newClasses: MediaQueryAlias[]) {
    const el = this._document.documentElement;
    this.clearDocumentMediaClasses().then((res) => {
      newClasses.forEach((str) => {
        const classStr = `--${str}`;
        this.renderer.addClass(el, classStr);
      });
    });
  }

  public observe(): Observable<MediaQueryAlias[]> {
    return this.breakpointObserver.observe(Object.values(aliasMap)).pipe(
      map((bpState) =>
        Object.keys(aliasMap).filter((s) => bpState.breakpoints[aliasMap[s]])
      ),
      finalize(() => this.clearDocumentMediaClasses()),
      tap((res: MediaQueryAlias[]) => {
        this.setDocumentMediaClasses(res);
        this._currentlyActive = res;
      })
    );
  }

  public clearDocumentMediaClasses(): Promise<null> {
    const el = this._document.documentElement;
    const elClasses = el.getAttribute('class');
    const prevClasses = elClasses ? elClasses.split(' ') : [];
    const regex = /--/;
    return new Promise<null>((resolve) => {
      prevClasses.forEach((cls) => {
        if (cls.match(regex)) this.renderer.removeClass(el, cls);
      });
      resolve(null);
    });
  }

  /** Shorthand for isActive (boolean getter) */
  public is(bp: MediaQueryAlias | MediaQueryAlias[]) {
    const query =
      typeof bp === 'string'
        ? aliasMap[bp as MediaQueryAlias]
        : (bp as MediaQueryAlias[])?.map((s) => aliasMap[s]);
    return this.breakpointObserver.isMatched(query);
  }
}
