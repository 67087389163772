import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { CodeTableService } from '../core/code-table.service';
import { SystemDateFormatService } from '@exl-ng/mulo-core';
import { EntityService } from '../core/entity.service';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../shared/utils/url.utils';
import { JwtUtilService } from '../core/jwt-util.service';
import { ActivityDetails } from '../shared/interfaces/activity.interface';
import { Observable, combineLatest, map } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ActivitiesService extends EntityService {
    institutionCode: string;

    constructor(
        private _t: TranslateService,
        private _cts: CodeTableService,
        private _sdfs: SystemDateFormatService,
        private _md: MatDialog,
        private _http: HttpClient,
        private _jus: JwtUtilService,
        private _router: Router
    ) {
        super(_t, _cts, _sdfs, _md, _http, _jus, _router);
        this.institutionCode = UrlUtils.getParam('institution');
        this.api = RestApiBase.ActivityApi;
    }

    save(activity: ActivityDetails) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this._http.post(
            `${RestApiBase.ActivityApi.base}/${activity.id}`,
            activity,
            {
                params,
            }
        );
    }

    saveExtendedActivity(activity: ActivityDetails, path: string) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this._http.post(
            `${RestApiBase.ActivityApi.base}/${path}`,
            activity,
            {
                params,
            }
        );
    }

    addSectionItem(sectionKey, item, id) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this._http.post(
            `${RestApiBase.ActivityApi.GET_ACTIVITY}/${this.entityId}/${sectionKey}/${id}`,
            item,
            { params }
        );
    }

    addNewActivity(body: ActivityDetails) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('userId', this._jus.getUserID());
        return this._http.post(RestApiBase.ActivityApi.ADD_NEW_ACTIVITY, body, {
            params,
        });
    }

    getPossibleCountries() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );

        const t = (_) => this._t.instant(_);
        const sortList$ = map((table: any[]) =>
            table.sort((a, b) => t(a.code)?.localeCompare(t(b.code)))
        );

        return this._http
            .get(`${this.api.base}/possibleCountries`, { params })
            .pipe(sortList$);
    }

    searchStudents(searchText: string) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('searchText', searchText);

        return this._http.get(`${this.api.base}/searchStudentNames`, {
            params,
        });
    }

    possibleOrganizations(searchText: string) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('searchText', searchText);

        return this._http.get(`${this.api.base}/possibleOrganizations`, {
            params,
        });
    }

    possibleOptions(optionsField: string) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this._http.get(`${this.api.base}/${optionsField}`, { params });
    }

    possibleAttributes(selectedAttributes: Observable<any[]>) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        const convertToCode$ = map((attributes: any[]) => {
            const t = (_) => this._t.instant(_);
            return attributes
                .sort((a, b) => t(a.code)?.localeCompare(t(b.code)))
                .map((attribute) => attribute.code);
        });
        return combineLatest([
            selectedAttributes,
            this._http.get<any[]>(
                `${this.api.base}/${this.entityId}/attributes/possibleAttributes`,
                { params }
            ),
        ]).pipe(
            map(([selected, remaining]) => selected.concat(remaining)),
            convertToCode$
        );
    }

    possibleFilesContentTypes(categoryCode) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('activityCategoryCode', categoryCode);

        const convertToCode$ = map((types: any[]) => {
            const t = (_) => this._t.instant(_);
            return types
                .sort((a, b) => t(a.code)?.localeCompare(t(b.code)))
                .map((type) => type.code);
        });

        return this._http
            .get<any[]>(`${this.api.base}/possibleFilesContentTypes`, {
                params,
            })
            .pipe(convertToCode$);
    }
}
