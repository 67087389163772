<esp-deposit-form-section sectionTitle="{{'research.status.header.title' | customTranslate}}" [required]="required">
    <esp-deposit-publication-status></esp-deposit-publication-status>
</esp-deposit-form-section>

<esp-deposit-form-section sectionTitle="{{'research.status.info' | customTranslate}}" *ngIf="displayMoreInformation()" @scaleInOut>
    <esp-deposit-publication-details-journal-article [status]="status" *ngIf="isJournalArticle() || isBookReview()"></esp-deposit-publication-details-journal-article>
    <esp-deposit-publication-details-book-chapter *ngIf="isBookChapter()"></esp-deposit-publication-details-book-chapter>
    <esp-deposit-publication-details-conference-proceeding *ngIf="isConferenceProceedings()"></esp-deposit-publication-details-conference-proceeding>
    <esp-deposit-publication-details-book *ngIf="isBook()"></esp-deposit-publication-details-book>
</esp-deposit-form-section>

<esp-deposit-form-section  sectionTitle="{{'research.published.DOI' | customTranslate}}">
    <esp-deposit-doi placeholder="research.status.doi"></esp-deposit-doi>
</esp-deposit-form-section>

<esp-deposit-form-section sectionTitle="{{dateTitle() | customTranslate}}" *ngIf="displayDate()" @scaleInOut [required]="true">
    <esp-deposit-dates *ngIf="status !== statusConst.PUBLISHED"
                       [autoPopulated]="isDateAutoPopulated"
                       [range]="false"
                       [dateHint]="true"
                       [fromDate]="depositFormDataService.date"
                       [error]="depositFormDataService.mainForm?.errors?.validDate && depositFormDataService.date.touched"
                       [exlRequiredInput]="depositFormDataService.date">
    </esp-deposit-dates>
    <esp-deposit-published-date *ngIf="status === statusConst.PUBLISHED"></esp-deposit-published-date>

</esp-deposit-form-section>
