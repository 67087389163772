import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { ExternalOrganizationService } from '../../core/external-organization.service';
import { MatDialog } from '@angular/material/dialog';
import { SettingsDataService } from '../../settings/settings-data.service';
import { ExternalOrganization } from '../../shared/interfaces/external-organization.interface';
import { Settings } from '../../shared/configurations/settings.constant';
import { DepositForm } from '../../shared/configurations/deposit-form.constant';
import { Subject, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'esp-external-organization-field',
  templateUrl: './external-organization-field.component.html',
  styleUrls: ['./external-organization-field.component.scss']
})
export class ExternalOrganizationFieldComponent implements OnInit {

  @ViewChild('searchInput', { static: false, read: MatAutocompleteTrigger })
  searchInput: MatAutocompleteTrigger;

  @Input() organizationName: UntypedFormControl;
  @Input() organizationCode: UntypedFormControl;
  @Input() organizationId: UntypedFormControl;
  @Input() organizationCategory: UntypedFormControl;
  @Input() placeholder: string = this.translate.instant('research.deposit.searchable.fields.placeholder');
  @Input() label: string = '';
  @Input() isComfortablyReadable: boolean = false;
  @Input() newOrganizationType: string = 'esploro.organization.types.education';

  @Output() organizationSelected: EventEmitter<ExternalOrganization> = new EventEmitter();

  externalOrganizations: ExternalOrganization[];
  noValuesFound = false;
  organizationSearchInProgress = false;
  private externalOrganizationDestroy = new Subject();
  organizationNameCleaned = '';

  constructor(public settingsDataService: SettingsDataService,
    public externalOrganizationService: ExternalOrganizationService,
    public confirmDialog: MatDialog,
    public configurationHandlerService: ConfigurationHandlerService,
    public translate: TranslateService) {
  };

  ngOnInit(): void {
    this.listenToExternalOrganizationInput();
    this.listenWhenCleanForm();
  }

  listenWhenCleanForm() {
    this.organizationCode.registerOnChange(value => {
      if (value === null && this.organizationName?.value === null) {
        this.externalOrganizations = [];
      }
    });
  }

  listenToExternalOrganizationInput() {
    this.organizationName.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.noValuesFound = false;
        this.organizationCode.patchValue(null, { emitEvent: false });
        this.organizationName.updateValueAndValidity({ emitEvent: false });
      }),
      debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
      takeUntil(this.externalOrganizationDestroy))
      .subscribe(value => {
        if (value && value.length >= DepositForm.MIN_INPUT_LENGTH) {
          this.organizationSearchInProgress = true;
          this.organizationNameCleaned = value.trim().replace(/\s\s+/g, ' ');
          this.getExternalOrganizationAutoComplete(this.organizationNameCleaned);
          this.organizationCode.reset();
        }
      });
  }

  getExternalOrganizationAutoComplete(value) {
    this.externalOrganizationService.getExternalOrganizationAutoComplete(value).subscribe(
      data => {
        this.organizationSearchInProgress = false;
        if (data) {
          this.externalOrganizations = data as ExternalOrganization[];
          this.noValuesFound = !this.externalOrganizations.some(g => g.name === value);
        } else {
          this.noDataFound();
        }
      }
      , error => {
        this.organizationSearchInProgress = false;
        this.noDataFound();
      });
  }

  onExternalOrganizationSelect(organization: ExternalOrganization) {
    this.organizationId?.patchValue(organization.orgId, { emitEvent: false });
    this.organizationCategory?.patchValue(organization.category, { emitEvent: false });
    this.organizationCode.patchValue(organization.code, { emitEvent: false });
    this.organizationName.patchValue(organization.name, { emitEvent: false });
    this.searchInput.closePanel();
  }

  noDataFound() {
    this.noValuesFound = true;
    this.externalOrganizations = [];
  }

  addNewExternalOrganizationSelect(affiliation: string) {
    this.noValuesFound = false;

    this.externalOrganizationService.addExternalOrganization(affiliation, this.newOrganizationType).subscribe(
      (data: ExternalOrganization) => {
        this.organizationSearchInProgress = false;
        if (data) {
          this.organizationId?.patchValue(data.orgId, { emitEvent: false });
          this.organizationCategory?.patchValue(data.category, { emitEvent: false });
          this.organizationCode.patchValue(data.code, { emitEvent: false });
          this.organizationName.patchValue(data.name, { emitEvent: false });
        } else {
          this.noDataFound();
        }
      }
      , error => {
        this.organizationSearchInProgress = false;
        this.noDataFound();
      });
  }

  openConfirmationDialog() {
    return this.confirmDialog.open(ConfirmDialogComponent, {
      maxWidth: '500px',
      data: {
        title: 'research.popup.create.external.organization.title',
        message: 'research.popup.create.external.organization.message',
        actions: {
          confirm: 'research.popup.create.external.organization.save',
          cancel: 'research.popup.create.external.organization.cancel'
        },
        actionFocus: 'confirm'
      }
    });
  }

  onDialogCloseAttempt(affiliation: string) {
    affiliation = affiliation.trim().replace(/\s\s+/g, ' ');
    const dialog = this.openConfirmationDialog();
    dialog.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.addNewExternalOrganizationSelect(affiliation);

      }
    });
  }

  get numberOfItemsToDisplay() {
    return Settings.MAX_DISPLAYED_ITEMS_IN_DROP_DOWN;
  }

}

