/**
 * IANA-approved top-level media content types
 * @see https://www.iana.org/assignments/media-types/media-types.xhtml
 */
const ianaContentTypes = [
  'application',
  'audio',
  'font',
  'example',
  'image',
  'message',
  'model',
  'multipart',
  'text',
  'video',
];

export class FileUtils {
  public static getFileProfile(extension: string, mediaType?: string) {
    if (mediaType !== undefined) {
      // use the MIME type if available
      const typeParts = mediaType?.split('/') || ['', ''];

      switch (typeParts[1]?.toLowerCase()) {
        case 'zip':
        case 'x-zip-compressed':
        case 'rar':
        case '7z':
          return 'compressed';
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'vnd.openxmlformats-officedocument.wordprocessingml.template':
        case 'vnd.ms-word.document.macroEnabled.12':
        case 'vnd.ms-word.template.macroEnabled.12':
        case 'msword':
        case 'vnd.oasis.opendocument.text':
        case 'vnd.oasis.opendocument.text-template':
          return 'word';
        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'vnd.openxmlformats-officedocument.spreadsheetml.template':
        case 'vnd.ms-excel.sheet.macroEnabled.12':
        case 'vnd.ms-excel.template.macroEnabled.12':
        case 'vnd.ms-excel.addin.macroEnabled.12':
        case 'vnd.ms-excel.sheet.binary.macroEnabled.12':
        case 'vnd.ms-excel':
        case 'vnd.oasis.opendocument.spreadsheet':
        case 'vnd.oasis.opendocument.spreadsheet-template':
          return 'excel';
        case 'vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'vnd.openxmlformats-officedocument.presentationml.template':
        case 'vnd.openxmlformats-officedocument.presentationml.slideshow':
        case 'vnd.ms-powerpoint.addin.macroEnabled.12':
        case 'vnd.ms-powerpoint.presentation.macroEnabled.12':
        case 'vnd.ms-powerpoint.template.macroEnabled.12':
        case 'vnd.ms-powerpoint.slideshow.macroEnabled.12':
        case 'vnd.ms-powerpoint':
        case 'vnd.oasis.opendocument.presentation':
        case 'vnd.oasis.opendocument.presentation-template':
          return 'powerpoint';
        case 'html':
        case 'css':
        case 'javascript':
        case 'json':
          return 'code';
        case 'rtf':
        case 'x-rtf':
        case 'richtext':
          return 'rtf';
        case 'pdf':
          return typeParts[1];
        case 'x-research-info-systems':
          return 'text';
        default:
          if (ianaContentTypes.includes(typeParts[0])) {
            return typeParts[0];
          } else {
            return this.getFileProfileFromExt(extension);
          }
      }
    } else {
      // otherwise use extension as fallback
      return this.getFileProfileFromExt(extension);
    }
  }

  public static getFileProfileFromExt(extension: string) {
    if (extension.match(/^(gif|jpe?g|tiff?|png|svg|bmp)$/i)) {
      return 'image';
    } else if (extension.match(/^(zip|rar|dmg)$/i)) {
      return 'compressed';
    } else if (extension.match(/^(pdf)$/i)) {
      return 'pdf';
    } else if (extension.match(/^(docx?)$/i)) {
      return 'word';
    } else if (extension.match(/^(xlsx?)$/i)) {
      return 'excel';
    } else if (extension.match(/^(pptx?)$/i)) {
      return 'powerpoint';
    } else if (extension.match(/^(txt|rtf)$/i)) {
      return 'text';
    } else if (extension.match(/^(xml|json)$/i)) {
      return 'code';
    } else if (extension.match(/^(avi|mp4|mov|mkv)$/i)) {
      return 'video';
    } else if (extension.match(/^(mp3|wav)$/i)) {
      return 'audio';
    } else {
      return 'application';
    }
  }

  public static getHumanFileSize(size: number | string, decimals: number) {
    const bytes = Number(size);
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public static getExtension(fileName: string) {
    return fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
  }

  public static stripExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, '');
  }
}
