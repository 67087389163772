import {
  Component,
  OnInit,
  Input,
  HostBinding,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
  ElementRef,
  Directive,
  ContentChildren,
  QueryList,
  TemplateRef,
  AfterContentInit,
  OnDestroy,
  DoCheck,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, takeUntil, tap } from 'rxjs';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOptionSelectionChange, ThemePalette, MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { FocusMonitor } from '@angular/cdk/a11y';
import { componentDestroyed, MediaService, SvgViewboxDirective } from '@exl-ng/mulo-core';

import { SearchQuery } from '../../models/search-query.model';
import { randomString } from '../../utils';
import { SearchbarOptionsComponent } from './searchbar-options/searchbar-options.component';
import {
  HeightInAnimation,
  HeightOutAnimation,
  OpacityInAnimation,
  OpacityOutAnimation,
} from '../../animations';
import { SearchbarAdvRootDirective } from './searchbar-adv-root.directive';
import { FloatLabelType, MatFormFieldAppearance, MatFormField, MatPrefix, MatLabel, MatSuffix } from '@angular/material/form-field';
import { HighlightTextPipe } from '../../pipes/highlight-text.pipe';
import { MatCard } from '@angular/material/card';
import { AriaProgressBarDirective } from '../../directives/aria-mat-progress.directive';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';

interface SelectOption {
  value: string;
  viewValue: string;
}
interface Selector {
  label: string;
  ariaLabel: string;
}
type SearchbarState = 'expanded' | 'collapsed';

@Directive({
    selector: '[muloSearchbarAutocompleteTemplate]',
    standalone: true,
})
export class SearchbarAutocompleteTemplateDirective {
  @Input() muloSearchbarAutocompleteTemplate:
    | 'suggestions'
    | 'results'
    | 'scopes';

  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
    selector: 'mulo-searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss'],
    animations: [
        HeightInAnimation,
        HeightOutAnimation,
        OpacityInAnimation,
        OpacityOutAnimation,
    ],
    standalone: true,
    imports: [
        NgIf,
        MatFormField,
        MatPrefix,
        MatLabel,
        MatInput,
        FormsModule,
        MatAutocompleteTrigger,
        ReactiveFormsModule,
        MatIconButton,
        MatSuffix,
        MatIcon,
        SvgViewboxDirective,
        MatProgressBar,
        AriaProgressBarDirective,
        MatButton,
        MatCard,
        MatAutocomplete,
        NgFor,
        MatOption,
        NgTemplateOutlet,
        HighlightTextPipe,
    ],
})
export class SearchbarComponent
  implements OnInit, AfterContentInit, OnDestroy, DoCheck, OnChanges
{
  /**
   * Unique ID for whn there multiple concurrent instances of a searchbar in the DOM
   */
  @Input() id: string = randomString();
  /**
   *  The placeholder text before typing
   */
  @Input() placeholder: string;
  /**
   *  The label (if any) of the search (corresponds to Material API)
   */
  @Input() label: string;
  /**
   * A floating label, when present (corresponds to Material API)
   */
  @Input() floatLabel: FloatLabelType = 'auto';
  /**
   * Size of the searchbar
   */
  @Input() size: 'mini' | 'small' | 'normal' | 'large' | 'mega' | null =
    'normal';
  /**
   * Display the searchbar as outlined, or underlined
   */
  @Input() appearance: 'outline' | 'underline' = 'outline';
  /**
   * Show/hide the search button
   */
  @Input() searchButton = true;
  /**
   * Progress state (affects progress bar)
   */
  @Input() inProgress = false;
  /**
   * Show/hide the clear input button inside the input field
   */
  @Input() clearInputButton = true;
  /**
   * Set theme color of search elements (buttons, outline)
   */
  @Input() color: ThemePalette = 'primary';
  /**
   * When searchbar is over dark background
   */
  @Input() overDark = false;
  /**
   * Set the search value from parent
   */
  @Input() inputValue: string;

  @Output() stateChange = new EventEmitter<SearchbarState>();

  /**
   * Customize the word that appears before the scope term (i.e "foo by bar")
   */
  @Input() autocompleteRelationWord = 'in';

  /**
   * Autocomplete result array
   */
  @Input() autocompleteResults: Array<string> = [];

  @Input() autocompleteSuggestions: Array<string> = [];
  @Input() autocompleteScopes: Array<string> = [];
  @Input() autoCompleteSuggestionTemplate: TemplateRef<any>;
  @Input() matAutocomplete: MatAutocomplete;

  /**
   * get a hold of the autocomplete trigger, to be able to open/close it programmatically
   *
   * @internal
   */
  @ViewChild(MatAutocompleteTrigger, { static: false })
  autocomplete: MatAutocompleteTrigger;

  @ContentChildren(SearchbarAutocompleteTemplateDirective)
  autocompleteTemplates: QueryList<SearchbarAutocompleteTemplateDirective>;
  @Output() autocompleteSelected = new EventEmitter<object>();
  @Input() get autocompleteSearchBy() {
    return this._autocompleteSearchBy;
  }
  set autocompleteSearchBy(val) {
    this._autocompleteSearchBy = val;
  }

  /**
   * Input emitter; emit the changing value in the main input
   */
  @Output() inputChanged = new EventEmitter<string>();

  /**
   * Search submit emitter
   */
  @Output() submit = new EventEmitter<SearchQuery>();

  /**
   * emitter for clicks on the search trigger (placeholding button, mainly for small screen views)
   */
  @Output() triggerClick = new EventEmitter<void>();

  /**
   * get a hold of the input
   */
  @ViewChild(MatInput, { static: false }) inputElement: MatInput;
  /**
   * get a hold of the search submit button
   */
  @ViewChild('submitButton', { static: false }) submitButton: MatButton;
  /**
   * get a hold of projected search options
   */
  @ContentChildren(SearchbarOptionsComponent)
  options: QueryList<SearchbarOptionsComponent>;
  /**
   * get template references for projectable placements before and after the input
   */
  @ViewChild('beforeInput') beforeInputContainer: ElementRef;
  @ViewChild('afterInput') afterInputContainer: ElementRef;

  /**
   * Whether the host component is in focus or not, or any of its focusable children
   */
  @HostBinding('class.mulo-focused') public focused = true;

  @Input() collapseBtnLabel = 'Close';

  @Input() advSearchRoot: SearchbarAdvRootDirective;
  @Input() advSearchLabel = 'Advanced Search';
  @Input() advSearchCloseAriaLabel = 'Close';
  @Input() advSearchSearchLabel = 'Search';
  @Input() set advSearchStartOpen(val: boolean) {
    const isChanged = this._advSearchStartOpen !== val;
    this._advSearchStartOpen = val;

    if (isChanged) {
      // setTimeout to wait for view init
      setTimeout(() => {
        if (val) {
          if (this.advSearchTempl) {
            this.advSearchRoot?.openAdvSearch(this.advSearchTempl);
          }
        } else {
          this.advSearchRoot?.closeAdvSearch();
        }
      });
    }
  }
  get advSearchStartOpen() {
    return this._advSearchStartOpen;
  }
  @ViewChild('advSearch') advSearchTempl: TemplateRef<any>;
  advSearchDisableAnim = true;
  /**
   * Text input form control
   *
   * @internal
   */
  inputControl = new UntypedFormControl('');

  /**
   * Observable monitoring focus on elements within the searchbar component
   */
  componentFocus$ = this.focusMonitor
    .monitor(this.elRef.nativeElement, true)
    .pipe(
      tap((source) => {
        this.focused = !!source;
        setTimeout(
          () => {
            if (this.size === 'mini') {
              this.state = this.focused ? 'expanded' : 'collapsed';
            }
            // delay added to prevent sudden close/open when clicking on options
          },
          this.focused ? 0 : 300
        );
      }),
      takeUntil(componentDestroyed(this))
    );

  emittingSubmitLock$ = new BehaviorSubject<boolean>(false);

  /**
   * Flags for presence of projected options
   */
  hasOptionsBefore = false;
  hasOptionsAfter = false;

  /**
   * Expand the searchbar when collapsed when available
   */
  expanding = false;

  /**
   * Width of the pre-input option container
   */
  preInputContainerWidth = 0;

  /**
   * Width of the post-input option container
   */
  postInputContainerWidth = 0;

  @Input() set state(val: SearchbarState) {
    this._state = val;
    this.stateChange.emit(val);
  }

  @Input()
  set focus(val: boolean) {
    this._focus = val;
  }
  get focus() {
    return this._focus;
  }
  /**
   * Set host classes according to features
   */
  @HostBinding('class') public get classList(): string {
    return [
      'mulo-searchbar',
      this.sizeClass,
      this.labelClass,
      this.searchButtonClass,
      this.overDarkClass,
    ]
      .join(' ')
      .trim();
  }
  @HostBinding('class.is-expanded') get isExpanded() {
    return this._state === 'expanded';
  }
  @HostBinding('class.is-collapsed') get isCollapsed() {
    return this._state === 'collapsed';
  }

  /**
   *  Applicable state, for use when space is limited, like small screens
   */
  private _state: SearchbarState = 'collapsed';
  /**
   * Searchbar focus event
   */
  private _focus = false;
  private _autocompleteSearchBy: Array<string> = [];
  private _advSearchStartOpen = false;

  constructor(
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef,
    public media: MediaService
  ) {}

  /**
   * Subscribe to main input value changes
   *
   * @internal
   */
  ngOnInit() {
    this.inputControl.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => this.emitInput());

    this.componentFocus$.subscribe();

    // disable adv search open animation on init to prevent getting stuck
    setTimeout(() => (this.advSearchDisableAnim = false), 500);
  }
  /**
   * @internal
   */
  ngAfterContentInit() {
    if (this.options.length) {
      this.setOptions();
    } else {
      this.resetOptions();
    }
  }

  /**
   * @internal
   */
  ngOnDestroy() {}

  ngDoCheck() {
    const pre = this.beforeInputContainer?.nativeElement?.scrollWidth;
    const post = this.afterInputContainer?.nativeElement?.scrollWidth;

    if (pre !== this.preInputContainerWidth) {
      this.preInputContainerWidth = pre;
    }
    if (post !== this.postInputContainerWidth) {
      this.postInputContainerWidth = post;
    }
  }

  /**
   * @internal
   */
  ngOnChanges(change: SimpleChanges) {
    if (change.inputValue) {
      setTimeout(() => {
        this.inputControl.setValue(this.inputValue, { emitEvent: false });
      });
    }
    if (change.focus) {
      if (this.focus === true) {
        this.focusInput();
      } else if (this.submitButton) {
        this.submitButton.focus();
      }
    }
  }

  toggleAdvSearchOpen(toOpen: boolean, advSearchTempl = this.advSearchTempl) {
    this.advSearchTempl = advSearchTempl;
    this.advSearchStartOpen = toOpen;
  }

  /**
   * @internal
   */
  setOptions() {
    const optionsExist = (placement) =>
      this.options.toArray().filter((option) => option.placement === placement)
        .length > 0;

    this.hasOptionsBefore = optionsExist('before');
    this.hasOptionsAfter = optionsExist('after');
  }

  /**
   * @internal
   */
  resetOptions() {
    this.hasOptionsBefore = null;
    this.hasOptionsAfter = null;
  }

  // Class selectors getters
  private get sizeClass(): string {
    return `is-${this.size}-size`;
  }

  private get labelClass(): string {
    return !this.label ? 'hasnt-label' : '';
  }

  private get searchButtonClass(): string {
    return !this.searchButton ? `hasnt-search-button` : '';
  }

  private get overDarkClass(): string {
    return this.overDark ? 'is-on-dark' : '';
  }

  /**
   * Style of the entire form-field corresponds to Angular Material form field values
   */
  public get inputAppearance(): MatFormFieldAppearance {
    return this.appearance === 'underline' ? 'fill' : 'outline';
  }

  /**
   * @internal
   */
  clearInput() {
    this.inputControl.setValue('');
    this.inputControl.reset();
  }

  /**
   * @internal
   */
  submitSearch(event?) {
    if (event instanceof MatOptionSelectionChange) {
      this.emittingSubmitLock$.next(true);
      this.emitSubmit(event.source.value);
      const value = event.source.value.scope
        ? this.inputControl.value
        : Object.values(event.source.value)[0];
      this.inputControl.setValue(value, { emitEvent: false });
      setTimeout(() => {
        this.emittingSubmitLock$.next(false);
      }, 300);
    } else if (event instanceof MouseEvent || event instanceof KeyboardEvent) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.emittingSubmitLock$.getValue()) {
        this.emitSubmit({ query: this.inputControl.value });
      }
    }

    this.autocomplete.closePanel();
  }

  /**
   * @internal
   */
  emitInput() {
    this.inputChanged.emit(this.inputControl.value);
  }

  /**
   * @internal
   */
  emitSubmit(value?) {
    this.submit.emit(value ? value : this.inputControl.value);
  }

  /**
   * Collapse the searchbar when expanded when available
   */
  handleCollapseClick(event) {
    event.target.blur();
    setTimeout(() => (this.state = 'collapsed'));
  }

  /**
   * Emit click on the search trigger placeholder
   */
  handleSearchTriggerClick() {
    this.triggerClick.emit(null);
  }

  /**
   * Trigger a focus on the main input
   */
  public focusInput() {
    if (this.inputElement) {
      this.inputElement.focus();
    }
  }

  autocompleteTemplate(name) {
    const templ = this.autocompleteTemplates.find(
      (t) => t.muloSearchbarAutocompleteTemplate === name
    );
    return templ;
  }

  get assessedScopes() {
    return this.autocompleteSearchBy && this.autocompleteSearchBy.length
      ? this.autocompleteSearchBy
      : this.autocompleteScopes;
  }

  inputWrapperWidth = () => `calc(100% - ${this.postInputContainerWidth}px)`;
  inputWrapperPadLeft = () => `${this.preInputContainerWidth}px`;
}
