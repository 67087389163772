<mat-list class="authors-list mulo-elevation-list" role="list">
    <div exl-transition-group="'flip-list'" style="position: relative">
        <ng-container
            *ngFor="
                let author of authors?.value;
                trackBy: trackByUserID;
                let i = index;
                first as isFirst;
                last as isLast
            "
        >
            <mat-list-item
                [@growInAnimation]="{
                    value: 'enter',
                    params: { endHeight: '64px', origin: 'center center' }
                }"
                [@growOutAnimation]="{
                    value: 'leave',
                    params: { origin: 'center center' }
                }"
                class="exl-default-list-item"
                exlTransitionGroupItem
                role="listitem"
            >
                <mat-icon
                    [svgIcon]="author.source === 'groupAuthor' ? 'organization' : 'account'"
                    matListItemIcon
                ></mat-icon>
                <exl-badge-auto-populated
                    *ngIf="isAutoPopulated(author.id)"
                    [tooltip]="'research.auto.populate.tooltip' | customTranslate"
                    color="primary"
                >
                </exl-badge-auto-populated>
                <span class="author-line1" matListItemTitle>
                    <span [muloElementAsHeading]="4" [id]="'author-name-' + i" class="author-name semibold">{{
                        author.name
                    }}</span>
                    <span class="author-separator"> — </span>
                    <mat-form-field appearance="outline" class="author-role is-inline no-subscript">
                        <mat-label>{{ 'research.people.role' | customTranslate }}</mat-label>
                        <mat-select
                            (ngModelChange)="author.role = $event; changeRole(i, $event)"
                            [attr.aria-description]="
                                'research.people.role.ariaLabel' | translate : { '0': author.name }
                            "
                            [ngModel]="author.role"
                        >
                            <mat-option *ngFor="let role of rolesTranslations" [value]="role[0]">
                                {{ role[1] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>

                <span
                    *ngIf="author.affiliationCode || author.orcid || author.sourceAffiliation"
                    class="author-line2"
                    matListItemLine
                >
                    <small>
                        <span *ngIf="author.affiliationCode">{{
                            author.affiliationCode | defaultTranslate : { default: author.affiliationName }
                        }}</span
                        ><span *ngIf="author.orcid"
                            ><span *ngIf="author.affiliationCode" class="author-separator"> / </span
                            ><mulo-orcid-link
                                [idDescription]="'research.general.orcid.profile.tooltip' | translate"
                                [logoTooltip]="'research.general.orcid.website.tooltip' | translate"
                                [orcidId]="author.orcid"
                            ></mulo-orcid-link
                        ></span>
                        <span *ngIf="!author.affiliationCode && author.sourceAffiliation">{{
                            author.sourceAffiliation
                        }}</span>
                    </small>
                </span>

                <div class="btns-wrap" matListItemMeta>
                    <button
                        (click)="onAuthorShift(i, 'up')"
                        *ngIf="!isFirst"
                        [attr.aria-describedby]="'author-name-' + i"
                        [attr.aria-label]="'research.aria.deposit.authors.author.shift.up' | translate"
                        mat-icon-button
                        type="button"
                    >
                        <mat-icon svgIcon="arrow-up"></mat-icon>
                    </button>
                    <button
                        (click)="onAuthorShift(i, 'down')"
                        *ngIf="!isLast"
                        [attr.aria-describedby]="'author-name-' + i"
                        [attr.aria-label]="'research.aria.deposit.authors.author.shift.down' | translate"
                        mat-icon-button
                        type="button"
                    >
                        <mat-icon svgIcon="arrow-down"></mat-icon>
                    </button>

                    <button
                        (click)="onAuthorRemove(i)"
                        *ngIf="!author.owner"
                        [attr.aria-describedby]="'author-name-' + i"
                        [attr.aria-label]="'research.aria.deposit.authors.author.remove' | translate"
                        mat-icon-button
                        type="button"
                    >
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                </div>
            </mat-list-item>
        </ng-container>
    </div>
</mat-list>
