import { Injectable } from '@angular/core';
import { Configuration } from '../shared/interfaces/configuration.interface';
import { ResearcherOrganization } from '../shared/interfaces/researcher-organization.interface';
import { ResearcherDepositConfigurations } from '../shared/interfaces/researcher-deposit-configuration-data.interface';
import { EsploroEntity } from '../shared/interfaces/esploro-entity';
import { PortalSearchScope } from '../shared/configurations/portal-search.constant';
import { ActivitiesCourseSection } from '../shared/interfaces/view-configuration/portal-activities-configuration.interface';
import { ResearcherProfileGeneralConfiguration } from '../shared/interfaces/researcher-profile-general-configuration.interface';
import { PortalOrgTreeConfiguration } from '../shared/interfaces/view-configuration/portal-org-tree-configuration.interface';
import { PortalActivityTabConfiguration } from '../shared/interfaces/view-configuration/portal-activity-tab-configuration.interface';
import { MappingTable } from '../shared/interfaces/mapping-table.interface';
import { UrlUtils } from '../shared/utils/url.utils';

@Injectable()
export class ConfigurationHandlerService {
    private _config: Configuration;
    private _depositConfig: any;

    constructor() {
        const code = UrlUtils.getParam('institution');
        const cached = JSON.parse(
            localStorage.getItem('espConfig' + (code ? '_' + code : '')),
        );
        this._config = (cached || {}) as Configuration;
    }

    get config(): Configuration {
        return this._config;
    }

    set config(value: Configuration) {
        this._config = value;
    }

    enrichConfiguration(extendedeConfig) {
        this._config = Object.assign(this._config, extendedeConfig);
    }

    getMappingTable(mappingTableName: string): MappingTable[] {
        return this.config.mappingTables?.[mappingTableName] || [];
    }

    getCustomerParameter(param: string) {
        return this.config.customerParameters?.[param];
    }

    getFeatureFlagEnabled(param: string): boolean {
        return this.config.featureFlags?.[param];
    }

    isCustomerParameterEnabled(param: string): boolean {
        return this.getCustomerParameter(param) === 'true';
    }

    getSystemDateFormat() {
        return this.getCustomerParameter('esploro_date_format');
    }

    isEsploroEditionAdvanced(): boolean {
        const esploroEdition: string =
            this.getCustomerParameter('esploro_edition');
        return esploroEdition === 'advanced';
    }

    isProfileActivate() {
        return (
            this.getCustomerParameter('esploro_public_profiles_active') ===
            'true'
        );
    }

    get depositConfig() {
        return this._depositConfig;
    }

    set depositConfig(value) {
        this._depositConfig = value;
    }

    getCustomizationThemeing() {
        return this.config.customization;
    }

    getDefaultDisplayLanguage() {
        return this.config.customization.defaults.defaultDisplayLanguage;
    }

    getDefaultLogoUrl() {
        return this.config.customization.defaults.logoUrl;
    }

    getMainInstitution(): ResearcherOrganization {
        const mainInst = this.config.mainInstitution as ResearcherOrganization;
        if (mainInst) {
            return mainInst;
        }
        return undefined;
    }

    getMainInstitutionName() {
        const mainInst = this.config.mainInstitution as ResearcherOrganization;
        if (mainInst) {
            return mainInst.organizationName;
        }
        return '';
    }

    getMainInstitutionCode() {
        const mainInst = this.config.mainInstitution as ResearcherOrganization;
        if (mainInst) {
            return mainInst.organizationCode;
        }
        return '';
    }

    getAuthenticationProfile() {
        return this.config.authProfile;
    }

    isExternalLogin() {
        const auth = this.getAuthenticationProfile();
        const authSystem = auth?.system;
        if (
            authSystem === 'SAML' ||
            authSystem === 'CAS' ||
            authSystem === 'SOCIAL_VIA_ALMA'
        ) {
            return true;
        }
        return false;
    }

    getResearcherIdentifiersOptions() {
        return this.config.researcherIdentifiersOptions;
    }

    getExternalFacingBaseUrl() {
        let baseUrl = this.config.externalFacingBaseUrl;
        if (baseUrl.charAt(baseUrl.length - 1) === '/') {
            baseUrl = baseUrl.slice(0, -1);
        }

        return baseUrl;
    }

    getConfigurationByDefualtView() {
        return this.config.portalViewConfiguration?.configurationByView.default;
    }

    getPortalConfigurationByAssetType() {
        return this.getConfigurationByDefualtView()?.configurationByAssetType;
    }

    getPortalConfigurationByDefaultAssetType() {
        return this.getPortalConfigurationByAssetType()?.default;
    }

    getPortalConfigurationHeaderLinks() {
        return this.getConfigurationByDefualtView()?.headerFooter?.headerLinks;
    }

    getPortalConfigurationFooterLinks() {
        return this.getConfigurationByDefualtView()?.headerFooter?.footerLinks;
    }

    getPortalConfigurationHedearIncludeSignIn() {
        return this.getConfigurationByDefualtView()?.headerFooter
            ?.includeSignIn;
    }

    getPortalConfigurationHedearIncludePivot(): boolean {
        return this.getConfigurationByDefualtView()?.headerFooter?.includePivot;
    }

    getPortalConfigurationHedearIncludeDropdownMenu(): boolean {
        return this.getConfigurationByDefualtView()?.headerFooter
            ?.includeDropdownMenu;
    }

    getPortalConfigurationFooterIncludeCopyrights(): boolean {
        return this.getConfigurationByDefualtView()?.headerFooter
            ?.includeCopyrights;
    }

    getPortalConfigurationFooterIncludeLogo(): boolean {
        return this.getConfigurationByDefualtView()?.headerFooter
            ?.includeFooterLogo;
    }

    getPortalConfigurationSocialMediaLinks() {
        return (
            this.getConfigurationByDefualtView()?.headerFooter
                ?.socialMediaLinks || []
        );
    }

    getBriefDisplayAltmetrics(): boolean {
        const displayAltmetrics =
            this.getConfigurationByDefualtView()?.configurationByAssetType
                ?.default?.briefResults?.general?.displayAltmetrics;
        return displayAltmetrics;
    }

    getDarkLogoImg() {
        return this.config.darkLogoImg;
    }

    getLightLogoImg() {
        return this.config.lightLogoImg;
    }

    getFaviconImg() {
        return this.config.faviconImg;
    }

    getResearcherDepositConfigurations(): ResearcherDepositConfigurations {
        const depositConfiguration = this.config
            ?.researcherDepositConfiguration as ResearcherDepositConfigurations;
        if (depositConfiguration) {
            return depositConfiguration;
        }
        return undefined;
    }

    getResearcherProfileLockFieldsConfiguration() {
        return this.config.researcherProfileLockFieldsConfiguration;
    }

    getResearcherProfileGeneralConfiguration() {
        return (
            this.config.researcherProfileGeneralConfiguration ||
            ({} as ResearcherProfileGeneralConfiguration)
        );
    }

    getResearcherProfileSearchResultsConfiguration() {
        return this.config.researcherProfileSearchResultsConfiguration;
    }

    getProjectSearchConfiguration() {
        return this.config.portalProjectSearchConfiguration;
    }

    getPortalHomepageConfiguration() {
        return this.config.portalHomepageConfiguration?.homepageSections || [];
    }

    getPortalProjectConfiguration() {
        return this.config.portalProjectConfiguration;
    }

    getPortalActivitiesConfiguration() {
        return this.config.portaActivitiesConfiguration?.sections;
    }

    getPortalNonVisbleActivityCategoriesConfiguration() {
        return this.config.portalNonVisbleActivityCategoriesConfiguration;
    }

    isMultiLingualEnabled() {
        return this.config.isMultiLingualEnabled;
    }

    getUndefinedLanguage() {
        return this.config.undefinedLanguage;
    }

    isActivityCourseActive(courseField: string) {
        const courseSection = <ActivitiesCourseSection>(
            this.getPortalActivitiesConfiguration()?.find(
                (section) => section.code == 'activities.course',
            )
        );
        return !!courseSection[courseField];
    }

    getPortalResearchResourcesConfiguration() {
        return this.config.portalResourcesConfiguration?.sections;
    }

    getAdvancedSearchConfiguration() {
        return this.config.portalAdvancedSearchConfiguration;
    }

    getResetPwUrl() {
        return this.config.resetPwUrl;
    }

    getPortalSearchOptionsConfiguration() {
        return this.config.portalSearchOptionsConfiguration?.sections
            .filter((entity) => entity.active)
            .map((section) => section.code.split('.').pop());
    }

    getActiveEntities() {
        const data: EsploroEntity[] =
            this.getConfigurationByDefualtView()?.headerFooter
                ?.headerEntitiesLinks || [];

        const entityCustParamFlags = {
            profileLink: 'esploro_public_profiles_active',
        };

        const entityCustParamFlagsOrAdvanced = {
            projectLink: 'esploro_projects',
        };

        const entityRouteData = {
            portalLink: {
                path: 'search/outputs',
                qParams: {
                    query: 'any,contains,*',
                    page: 1,
                    enable_asterisk_search: true,
                },
            },
            profileLink: {
                path: '/search/researchers',
                qParams: {
                    query: '*',
                    page: 1,
                },
            },
            orgUnitLink: { path: '/research-units', qParams: {} },
            collectionLink: { path: '/collections', qParams: {} },
            resourcesLink: { path: '/resources', qParams: {} },
            projectLink: {
                path: 'search/projects',
                qParams: {
                    query: '*',
                    page: 1,
                },
            },
        };

        // remove the ones deactivated by config
        let resultList = data.filter((e) => e.active === true);

        // remove the ones deactivated by CP
        resultList = resultList.filter((e) => {
            const cpFlagName = entityCustParamFlags[e.code];
            if (cpFlagName) {
                return this.isCustomerParameterEnabled(cpFlagName);
            } else {
                return true;
            }
        });

        resultList = resultList.filter((e) => {
            const cpFlagName = entityCustParamFlagsOrAdvanced[e.code];
            if (cpFlagName) {
                return (
                    this.isCustomerParameterEnabled(cpFlagName) ||
                    this.isEsploroEditionAdvanced()
                );
            } else {
                return true;
            }
        });

        // add in route data
        resultList = resultList.map((e) => {
            const routeData = entityRouteData[e.code] || {};
            return { ...e, ...routeData };
        });
        return resultList;
    }

    getProfilesSearchScopesFromConfiguration() {
        return this.getActiveSearchScopes(
            this.getResearcherProfileSearchResultsConfiguration(),
            'research.profiles.search.scope.',
        );
    }

    getProjectsSearchScopesFromConfiguration() {
        return this.getActiveSearchScopes(
            this.getProjectSearchConfiguration(),
            'research.projects.scope.',
        );
    }

    getActiveSearchScopes(scopes, prefix) {
        const searchIndexesArr =
            scopes?.searchIndexes.filter((index) => index.active) || [];
        return searchIndexesArr.map(
            (index) => new PortalSearchScope(index.code, prefix),
        );
    }

    canEditEntityByConfig(customerParam: string, headerKey: string) {
        return (
            (this.getCustomerParameter(customerParam) === 'true' ||
                this.isEsploroEditionAdvanced()) &&
            this.getConfigurationByDefualtView().headerFooter[headerKey]
        );
    }

    getPortalOrgTreeConfig(): PortalOrgTreeConfiguration {
        return this.config.portalOrgTreeConfiguration;
    }

    getActivityDisplayTabConfiguration(): PortalActivityTabConfiguration[] {
        return this.config.activityDisplayTabConfiguration;
    }
}
