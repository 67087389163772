import {
  Directive,
  ContentChildren,
  NgModule,
  Output,
  EventEmitter,
  HostListener,
  AfterContentInit,
  Optional,
  HostBinding,
  ElementRef,
  Renderer2,
  QueryList,
  Input,
  OnDestroy,
} from '@angular/core';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { MatChipListbox } from '@angular/material/chips';
import { Subscription } from 'rxjs';

interface DropListA11yMove {
  delta: -1 | 1;
  event: KeyboardEvent;
  currentIndex: number;
  previousIndex: number;
}

@Directive({
    selector: '[muloDropListA11y]',
    host: {
        role: 'listbox',
    },
    standalone: true,
})
export class DropListA11yDirective implements AfterContentInit, OnDestroy {
  @ContentChildren(CdkDrag, { descendants: true })
  items; //: QueryList<CdkDrag>;

  @Input('muloDropListA11yEditClass') editingClass = 'mulo-a11y-list-in-edit';
  @Input('muloDropListA11yMoveClass') itemInTransitClass =
    'mulo-a11y-item-in-transit';
  @Input('muloDropListA11yFocusClass') itemInFocusClass =
    'mulo-a11y-item-in-focus';

  @Output() muloDropListA11yMove = new EventEmitter<DropListA11yMove>();
  @Output() muloDropListA11yEditing = new EventEmitter<number | null>();

  @HostBinding('tabIndex') tabindex = 0;

  private _editing = false;
  get editing(): boolean {
    return this._editing;
  }
  set editing(editing: boolean) {
    this._editing = editing;
    this.muloDropListA11yEditing.emit(editing ? this.activeItemIndex : null);
  }

  activeItemIndex = -1;
  isMatChipList = false;
  subscriptions = new Subscription();

  constructor(
    @Optional() private matChipList: MatChipListbox,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.isMatChipList = matChipList != null;
  }

  private keyManager: FocusKeyManager<any>;

  ngAfterContentInit() {
    this.items.forEach((item) => {
      item.focus = () => item.element?.nativeElement?.focus();
      item.element.nativeElement.tabIndex = -1;
      item.element.nativeElement.role = 'option';
    });
    if (this.isMatChipList) {
      this.matChipList.selectable = false;

      // Chips list focus subscription
      const matChipFocusSubs = this.matChipList.chipFocusChanges;
      this.subscriptions.add(
        matChipFocusSubs.subscribe((ev) => {
          this.matChipList._chips.forEach((chip, idx) => {
            if (ev.chip == chip) {
              this.activeItemIndex = idx;
            }
          });
        })
      );
    } else {
      this.keyManager = new FocusKeyManager(this.items)
        .withHorizontalOrientation('ltr')
        .withWrap();

      // Key change subscription
      const keyChangeSubs = this.keyManager.change;
      this.subscriptions.add(
        keyChangeSubs.subscribe((idx) => {
          this.activeItemIndex = idx;
        })
      );

      // Tab out subscription
      const keyTabOutSubs = this.keyManager.tabOut;
      this.subscriptions.add(
        keyTabOutSubs.subscribe(() => {
          this.onListBlur();
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onListBlur() {
    this.editing = false;
    this.activeItemIndex = -1;
    if (this.keyManager) {
      this.keyManager.updateActiveItem(-1);
      this.setItemClass(this.keyManager.activeItemIndex);
    }
    // set the container tabindex so it won't capture the focus
    this.tabindex = -1;
    this.renderer.removeClass(this.el.nativeElement, this.editingClass);
  }

  @HostListener('focusout', ['$event'])
  onFocusOut(ev: FocusEvent) {
    if (!ev.relatedTarget) {
      this.onListBlur();
    }

    // reset the tabindex on your way out so that it's focusable again
    this.tabindex = 0;
    if (this.isMatChipList && !this.matChipList.focused) {
      // disable edit mode on tabOut for matChipList
      this.editing = false;
    }
    // console.groupEnd();
  }

  @HostListener('focus', ['$event'])
  onContainerFocus(ev: FocusEvent) {
    // console.group("onContainerFocus", ev);
    if (!this.isMatChipList) {
      // as soon as the container gets focus, we want to redirect it
      // MatChipList handles this on its own, so it's not needed there
      this.keyManager.setFirstItemActive();
    } else {
      this.matChipList.focus();
    }
    // console.groupEnd();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(ev) {
    // console.group("onKeydown", ev);
    if (!this.isMatChipList) {
      this.keyManager.onKeydown(ev);
    }

    let direction: -1 | 1 = 1;
    switch (ev.code) {
      case 'ArrowUp':
      case 'ArrowLeft':
        direction = -1;
      case 'ArrowDown':
      case 'ArrowRight':
        if (this.editing) {
          const oldIdx =
            (this.activeItemIndex + (this.items.length - direction)) %
            this.items.length;

          this.setItemClass(this.activeItemIndex, 'active');

          this.muloDropListA11yMove.emit({
            delta: direction,
            event: ev,
            previousIndex: oldIdx,
            currentIndex: this.activeItemIndex,
          });
        } else {
          this.setItemClass(this.activeItemIndex, 'focus');
        }
        ev.preventDefault();
        break;
      case 'Space':
        if (this.activeItemIndex != -1) {
          this.toggleListEditMode(this.editing);
        }
        ev.preventDefault();
        break;
    }
    // console.groupEnd();
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(ev) {
    if (this.isMatChipList) {
      return null;
    }
    if (ev.target != this.keyManager.activeItem?.element?.nativeElement) {
      this.editing = false;
      this.items.forEach((item, idx) => {
        this.renderer.removeClass(
          item.element.nativeElement,
          this.itemInFocusClass
        );
        if (ev.target == item.element?.nativeElement) {
          this.keyManager.setActiveItem(idx);
        }
      });
    }
    // console.groupEnd();
  }

  setItemClass(idx: number, mode?: 'active' | 'focus') {
    const items: QueryList<CdkDrag> = this.items;

    items.toArray().forEach((item) => {
      const _item = item.element.nativeElement;
      this.renderer.removeClass(_item, this.itemInFocusClass);
      this.renderer.removeClass(_item, this.itemInTransitClass);
    });

    if (idx >= 0) {
      const nextItem = items.toArray()[idx].element.nativeElement;
      if (mode) {
        this.renderer.addClass(nextItem, this.itemInFocusClass);
      }
      if (mode && mode === 'active') {
        this.renderer.addClass(nextItem, this.itemInTransitClass);
      }
    }
  }

  toggleListEditMode(state?: boolean) {
    this.editing = state ? false : true;
    const el = this.el.nativeElement;
    if (state === true) {
      this.renderer.removeClass(el, this.editingClass);
      this.setItemClass(this.activeItemIndex, 'focus');
    } else {
      this.renderer.addClass(el, this.editingClass);
      this.setItemClass(this.activeItemIndex, 'active');
    }
  }
}


