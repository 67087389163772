import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EditionGuard } from './core/edition-guard.service';
import { decommissionGuard } from './core/decommissionGuard';
import { TombstonePageComponent } from './tombstone-page/tombstone-page.component';
import { routes } from './app.routes';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'unavailable',
                    component: TombstonePageComponent,
                },
                {
                    path: '',
                    canActivateChild: [decommissionGuard],
                    children: routes,
                },
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
                bindToComponentInputs: true,
            },
        ),
    ],
    exports: [RouterModule],
    providers: [EditionGuard],
})
export class AppRoutingModule {}
