import {
    Component,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { DepositFormService } from '../../deposit-form.service';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { ConfigurationHandlerService } from '../../../core/configuration-handler.service';
import { MappingTable } from '../../../shared/interfaces/mapping-table.interface';
import { ResearcherDepositConfigurations } from '../../../shared/interfaces/researcher-deposit-configuration-data.interface';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

export interface Pair {
    code: string;
    name: string;
}

@Component({
    selector: 'esp-deposit-type',
    templateUrl: './deposit-type.component.html',
    styleUrls: ['./deposit-type.component.scss'],
    host: { class: 'esp-deposit-type' },
    animations: [GrowInAnimation, GrowOutAnimation],
})
export class DepositTypeComponent implements OnInit, OnDestroy {
    @Output() private selectedType: EventEmitter<any> = new EventEmitter();
    @Output() private clearSelection: EventEmitter<any> = new EventEmitter();

    assetCategories;
    resourceTypesMT: MappingTable[];
    resourceTypeGroups = [];
    labels;

    topLevelTitle: string;
    topLevelIcon: string;
    topLevelValues: string[] = [];

    selectedTypeCtrl: UntypedFormControl = new UntypedFormControl();
    typeFilterCtrl: UntypedFormControl = new UntypedFormControl();
    filteredTypeGroups: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    _onDestroy = new Subject<void>();
    _onDestroySelected = new Subject<void>();

    constructor(
        private depositFormService: DepositFormService,
        public depositFormDataService: DepositFormDataService,
        private configurationHandlerService: ConfigurationHandlerService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.getData();
        this.selectedTypeCtrl.setValidators(
            this.depositFormDataService.type.validator
        );
    }

    getData() {
        const researcherDepositConfigurations =
            this.configurationHandlerService.getResearcherDepositConfigurations();
        this.setTopLevelData(researcherDepositConfigurations);

        this.resourceTypesMT = this.configurationHandlerService.getMappingTable(
            'EsploroResourcetypeMapping'
        ) as MappingTable[];
        this.assetCategories = this.getResourceTypeCategories();

        const codes = this.resourceTypesMT.map((row) => {
            return row.target;
        });

        if (researcherDepositConfigurations?.resourceTypeMode !== 'DEFAULT') {
            codes.push(this.topLevelTitle);
        }

        this.translate.get(codes).subscribe((res: Object) => {
            this.labels = res;
            this.buildResourceTypeDropDown(researcherDepositConfigurations);
        });

        this.filteredTypeGroups.next(
            this.copyTypeGroups(this.resourceTypeGroups)
        );

        this.listenToTypeFilter();
        this.listenToSelectedType();

        if (this.depositFormDataService.isDraft) {
            this.selectedTypeCtrl.setValue(
                this.depositFormDataService.type.value,
                { emitEvent: false }
            );
            this.emitSelectedTypeEvent();
        }
    }

    listenToTypeFilter() {
        this.typeFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterTypeGroups();
            });
    }

    listenToSelectedType() {
        this.selectedTypeCtrl.valueChanges
            .pipe(takeUntil(this._onDestroySelected))
            .subscribe((value) => {
                const index = value.indexOf('.');
                if (index !== -1) {
                    const category = value.substr(0, index);
                    if (
                        this.depositFormDataService.category.value &&
                        this.depositFormDataService.category.value !== category
                    ) {
                        this.clearReservedRegisterDoi();
                    }
                    this.depositFormDataService.updateCategory(category);
                }
                this.depositFormDataService.updateType(value);
                this.emitSelectedTypeEvent();
            });
    }

    getResourceTypeCategories() {
        return this.resourceTypesMT
            .filter(
                (row) =>
                    row.source1 === '1' &&
                    row.target !== 'etd' &&
                    row.target !== 'etdex'
            )
            .map((row) => {
                return row.target;
            });
    }

    filterTypeGroups() {
        if (!this.resourceTypeGroups) {
            return;
        }

        let search = this.typeFilterCtrl.value;
        const typeGroupsCopy = this.copyTypeGroups(this.resourceTypeGroups);
        if (!search) {
            this.filteredTypeGroups.next(typeGroupsCopy);
            return;
        } else {
            search = search.toLowerCase();
        }

        const types = typeGroupsCopy.filter((group) => {
            const showGroup = group.name.toLowerCase().indexOf(search) !== -1;
            if (!showGroup) {
                group.types = group.types.filter(
                    (type) => type.name.toLowerCase().indexOf(search) !== -1
                );
            }
            return group.types.length > 0;
        });

        this.filteredTypeGroups.next(types);
    }

    getResourceTypeListPerCategory(category) {
        const types = this.resourceTypesMT
            .filter((row) => row.source1 === '2' && row.source2 === category)
            .map((row) => {
                return { code: row.target, name: this.labels[row.target] };
            });
        types.sort(this.alphabeticallySortByName);
        return types;
    }

    copyTypeGroups(typeGroups) {
        const typeGroupsCopy = [];
        typeGroups.forEach((typeGroup) => {
            typeGroupsCopy.push({
                code: typeGroup.code,
                icon: typeGroup.icon,
                name: typeGroup.name,
                types: typeGroup.types.slice(),
            });
        });
        return typeGroupsCopy;
    }

    emitSelectedTypeEvent() {
        this.selectedType.emit();
    }

    alphabeticallySortByName(a: Pair, b: Pair): number {
        if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    setTopLevelData(
        researcherDepositConfigurations: ResearcherDepositConfigurations
    ) {
        if (
            researcherDepositConfigurations?.resourceTypeMode === 'SUGGESTED' ||
            researcherDepositConfigurations?.resourceTypeMode ===
                'SUGGESTED_ONLY'
        ) {
            this.topLevelTitle = 'research.deposit.assettype.mode.suggested';
            this.topLevelIcon = 'star';
            this.topLevelValues =
                researcherDepositConfigurations.suggestedResourceTypes;
        } else if (
            researcherDepositConfigurations?.resourceTypeMode ===
            'FREQUENTLY_USED'
        ) {
            this.topLevelTitle = 'research.deposit.assettype.mode.frequently';
            this.topLevelIcon = 'clock';
            this.topLevelValues =
                researcherDepositConfigurations.frequentlyUsedResourceTypes
                    .filter((type) => !type.startsWith('etd'))
                    .slice(0, 6);
        } else {
            this.topLevelTitle = '';
            this.topLevelIcon = '';
            this.topLevelValues = [];
        }
    }

    getTopLevelValuesForDropDown() {
        const topLevelValuesForDropDown = [];
        this.topLevelValues.forEach((type) => {
            topLevelValuesForDropDown.push({
                code: type,
                name: this.labels[type],
            });
        });
        topLevelValuesForDropDown.sort(this.alphabeticallySortByName);
        return topLevelValuesForDropDown;
    }

    buildResourceTypeDropDown(
        researcherDepositConfigurations: ResearcherDepositConfigurations
    ) {
        if (
            researcherDepositConfigurations?.resourceTypeMode !==
            'SUGGESTED_ONLY'
        ) {
            this.assetCategories.forEach((type) => {
                const types = this.getResourceTypeListPerCategory(type);
                this.resourceTypeGroups.push({
                    code: type,
                    name: this.labels[type],
                    types,
                });
            });
            this.resourceTypeGroups.sort(this.alphabeticallySortByName);
        }

        if (researcherDepositConfigurations?.resourceTypeMode !== 'DEFAULT') {
            const topLevelTypes = this.getTopLevelValuesForDropDown();
            this.resourceTypeGroups.unshift({
                code: this.topLevelTitle,
                icon: this.topLevelIcon,
                name: this.labels[this.topLevelTitle],
                types: topLevelTypes,
            });
        }
    }

    clearReservedRegisterDoi() {
        if (this.depositFormDataService.isReservedOrRegisteredDoi()) {
            this.depositFormDataService.updateDoi('');
            this.depositFormDataService.updateCleanReservedRegisterDoi(true);
            this.depositFormDataService.updateIsRegisteredDOI(false);
            this.depositFormDataService.updateIsReservedDOI(false);
            this.depositFormDataService.updateResearcherReservedDoi(false);
        }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
        this._onDestroySelected.next();
        this._onDestroySelected.complete();
    }
}
