<mat-form-field
    [muloInputExpander]="organizationName.value"
    [ngClass]="{ 'is-comfortably-readable': isComfortablyReadable }"
    class="field-root"
>
    <input
        #searchInput
        [formControl]="organizationName"
        [matAutocomplete]="auto"
        [placeholder]="placeholder"
        matInput
    />
    <mat-label>{{ label }}</mat-label>
    <mat-progress-bar *ngIf="organizationSearchInProgress" color="primary" mode="indeterminate"></mat-progress-bar>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option
            (onSelectionChange)="onExternalOrganizationSelect(organization)"
            *ngFor="let organization of externalOrganizations | slice : 0 : numberOfItemsToDisplay"
            [disabled]="organizationSearchInProgress"
            [value]="organization.code | defaultTranslate : { default: organization.name }"
        >
            <span
                [innerHTML]="
                    organization.code
                        | defaultTranslate : { default: organization.name }
                        | highlight : organizationNameCleaned
                "
                class="semibold"
            ></span>
        </mat-option>
        <mat-option
            (onSelectionChange)="onDialogCloseAttempt(organizationName.value)"
            *ngIf="
                organizationName?.value &&
                noValuesFound &&
                !organizationSearchInProgress &&
                configurationHandlerService.getResearcherProfileGeneralConfiguration().allowToAddNewOrganiztion
            "
            [value]="organizationName.value"
        >
            <span class="semibold"
                >{{ organizationName.value }}
                {{ 'research.no.external.affiliation.auto.complete.found.add.new.organization' | translate }}</span
            >
        </mat-option>
    </mat-autocomplete>
    <mat-error
        *ngIf="!organizationName.value"
        [innerHtml]="'research.settings.field.required.error.message' | translate"
    ></mat-error>
    <mat-error
        *ngIf="organizationName.value"
        [innerHtml]="'research.settings.field.selection.required.error.message' | translate"
    ></mat-error>
</mat-form-field>
