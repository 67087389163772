<div class="inner-wrapper">
    <div *ngIf="depositFormDataService.isReservedOrRegisteredDoi()" @growInAnimation @growOutAnimation>
        <div *ngIf="depositFormDataService.isReservedDOI.value" class="deposit-section-description">
            <span
                [innerHTML]="'research.status.doi.reservedExplanation' | customTranslate : translatedResourceType"
            ></span>
        </div>
        <div class="reserve-status">
            <span *ngIf="reserveDoiInProcess" class="margin-right-sm">{{
                'research.status.doi.reservedInProcess' | customTranslate
            }}</span>
            <span *ngIf="!reserveDoiInProcess && depositFormDataService.isReservedDOI.value" class="margin-right-sm"
                >{{ 'research.status.doi.reserved' | customTranslate }}
                <span class="semibold">{{ depositFormDataService.doi.value }}</span></span
            >
            <span
                *ngIf="!reserveDoiInProcess && !depositFormDataService.isReservedDOI.value"
                class="margin-right-sm semibold"
                >{{ depositFormDataService.doi.value }}</span
            >
            <button
                #dismissBtn
                (click)="dismissDoi()"
                *ngIf="depositFormDataService.isReservedDOI.value"
                class="has-icon"
                color="primary"
                mat-button
            >
                <mat-icon svgIcon="close"></mat-icon>
                <span>{{ 'research.status.doi.dismissDoiLink' | customTranslate }}</span>
            </button>
        </div>
    </div>
    <div
        *ngIf="!depositFormDataService.isReservedOrRegisteredDoi()"
        @growInAnimation
        @growOutAnimation
        class="field-wrap"
    >
        <mat-form-field [class.is-autopopulated]="isAutoPopulated" class="is-comfortably-readable">
            <input [formControl]="depositFormDataService.doi" matInput />
            <mat-icon
                *ngIf="isAutoPopulated"
                [matTooltip]="'research.auto.populate.tooltip' | customTranslate"
                matPrefix
                svgIcon="auto-populate"
            >
            </mat-icon>
            <mat-label>{{ placeholder | customTranslate }}</mat-label>
            <mat-hint>{{ 'research.status.doi.hint' | translate }}</mat-hint>
            <mat-error>{{ 'research.status.doi.error' | customTranslate }}</mat-error>
        </mat-form-field>
        <div *ngIf="isReservedDoiButtonVisible" class="margin-left-sm">
            <span>{{ 'research.status.doi.reserveMessage' | customTranslate }}</span>
            <button #reserveBtn (click)="requestReserveDoi()" color="primary" mat-button>
                {{ 'research.status.doi.reserveLink' | customTranslate }}
            </button>
        </div>
    </div>
</div>
