import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, takeUntil } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { DepositDoiService } from './deposit-doi.service';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { TranslateService } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-deposit-doi',
    templateUrl: './deposit-doi.component.html',
    styleUrls: ['./deposit-doi.component.scss'],
    animations: [GrowInAnimation, GrowOutAnimation],
})
export class DepositDoiComponent implements OnInit, OnDestroy {
    @Input() placeholder: string;
    @Input() hint: string;
    @ViewChild('dismissBtn') dismissBtn: MatButton;
    @ViewChild('reserveBtn') reserveBtn: MatButton;
    haveDoiConfigurationPerAssetType = false;
    reserveDoiInProcess = false;
    translatedResourceType = {};

    private doiDestroy = new Subject<void>();

    constructor(
        public depositFormDataService: DepositFormDataService,
        private doiService: DepositDoiService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.onChanges();
        this.checkIfhaveDoiConfigurationPerAssetType();
        this.setTranslatedResourceType();
    }

    onChanges(): void {
        this.depositFormDataService.doi.valueChanges
            .pipe(takeUntil(this.doiDestroy))
            .subscribe((value) => {
                if (!this.depositFormDataService.startPopulating) {
                    this.depositFormDataService.removeKeyFromAutoPopulatedArray(
                        AutoPopulateKeys.DOI
                    );
                }
            });
    }

    checkIfhaveDoiConfigurationPerAssetType() {
        this.doiService
            .haveReservedDoiButton(this.depositFormDataService.resourceType)
            .subscribe(
                (data) => {
                    this.haveDoiConfigurationPerAssetType = data as boolean;
                },
                (error) => (this.haveDoiConfigurationPerAssetType = false)
            );
    }

    setTranslatedResourceType() {
        const resourceType = this.depositFormDataService.resourceType || '_';
        this.translate.get(resourceType).subscribe((res: String) => {
            this.translatedResourceType['0'] = res.toLowerCase();
        });
    }

    get isAutoPopulated() {
        return this.depositFormDataService.isKeyAutoPopulated(
            AutoPopulateKeys.DOI
        );
    }

    get isReservedDoiButtonVisible() {
        return (
            this.haveDoiConfigurationPerAssetType &&
            !this.depositFormDataService.doi.value
        );
    }

    ngOnDestroy() {
        if (!this.doiDestroy.isStopped) {
            this.doiDestroy.next();
            //unsubscribe from the subject itself
            this.doiDestroy.unsubscribe();
        }
    }

    requestReserveDoi() {
        this.reserveDoiInProcess = true;
        this.doiService
            .getNewReserveDoi(this.depositFormDataService.resourceType)
            .subscribe((data) => {
                this.depositFormDataService.updateDoi(data);
                this.depositFormDataService.updateResearcherReservedDoi(true);
                this.depositFormDataService.updateIsReservedDOI(true);
                this.reserveDoiInProcess = false;
                setTimeout(() => this.dismissBtn.focus());
            });
    }

    dismissDoi() {
        this.depositFormDataService.updateDoi('');
        this.depositFormDataService.updateCleanReservedRegisterDoi(true);
        this.depositFormDataService.updateIsRegisteredDOI(false);
        this.depositFormDataService.updateIsReservedDOI(false);
        this.depositFormDataService.updateResearcherReservedDoi(false);
        setTimeout(() => this.reserveBtn.focus());
    }
}
