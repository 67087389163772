export class ResearcherAssetStatus {
    public static DECLINED = 'DECLINED';
    public static RETURNED = 'RETURNED';
    public static SUBMITTED = 'SUBMITTED';
    public static APPROVED = 'APPROVED';
    public static DRAFT = 'RESEARCHER_DRAFT';
    public static LIBRARIAN_DRAFT = 'DRAFT';
    public static UNDER_REVIEW = 'UNDER_REVIEW';
    public static IMPORTED = 'IMPORTED';
    public static RESUBMITTED = 'RESUBMITTED';
}
