import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { CodeLabelEntry } from '../shared/interfaces/code-label-entry.interface';
import { CodeTableService } from '../core/code-table.service';
import { ProjectDetails } from '../shared/interfaces/project.interface';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { JwtUtilService } from '../core/jwt-util.service';
import { SystemDateFormatService } from '@exl-ng/mulo-core';
import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
} from '@angular/forms';
import { Observable, of, finalize, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EntityService } from '../core/entity.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ProjectService extends EntityService {
    institutionCode;
    get projectId() {
        return this.entityId;
    }
    set projectId(id: string) {
        this.entityId = id;
    }

    projectTypes: CodeLabelEntry[] = [];
    loading: boolean;

    constructor(
        private _t: TranslateService,
        private _cts: CodeTableService,
        private _sdfs: SystemDateFormatService,
        private _md: MatDialog,
        private _http: HttpClient,
        private _jus: JwtUtilService,
        private _router: Router
    ) {
        super(_t, _cts, _sdfs, _md, _http, _jus, _router);
        this.api = RestApiBase.projectApi;
    }

    addNew(body: ProjectDetails) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('userId', this._jus.getUserID());
        return this._http.post(RestApiBase.projectApi.ADD_NEW_PROJECT, body, {
            params,
        });
    }

    save(project: ProjectDetails) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this._http.post(
            `${RestApiBase.projectApi.ALL_PROJECTS}/${this.projectId}`,
            project,
            {
                params,
            }
        );
    }

    isIdentifierUnique(identifier: string) {
        const params = new HttpParams()
            .set('projectIdentifier', identifier)
            .set('institution', this.institutionCode);
        return this._http.get(
            RestApiBase.projectApi.IS_PROJECT_IDENTIFIERUNIQUE,
            { params }
        );
    }

    addSectionItem(sectionKey, item, id) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this._http.post(
            `${RestApiBase.projectApi.ALL_PROJECTS}/${this.projectId}/${sectionKey}/${id}`,
            item,
            { params }
        );
    }

    validateProjectIdentifier(loading = null): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (!control.value) {
                return of(null);
            }
            return this.isIdentifierUnique(control.value).pipe(
                finalize(() => {
                    loading = false;
                }),
                map((isUnique) => {
                    return isUnique
                        ? null
                        : {
                              isNotUnique: true,
                          };
                })
            );
        };
    }

    deleteItemsByIds(sectionKey, listIds) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this._http.post(
            `${RestApiBase.projectApi.ALL_PROJECTS}/${this.projectId}/${sectionKey}/deleteItems`,
            listIds,
            { params }
        );
    }
}
