import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DepositAuthorsService } from './deposit-authors.service';
import { User } from '../../../shared/interfaces/user.model';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import {
    GrowInAnimation,
    GrowOutAnimation,
    SlideInAnimation,
    SlideOutAnimation,
} from '@exl-ng/mulo-common';
import {
    animate,
    group,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { EsploroCreatorStatus } from '../../../shared/interfaces/esploro-author.interface';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-deposit-authors',
    templateUrl: './deposit-authors.component.html',
    styleUrls: ['./deposit-authors.component.scss'],
    animations: [
        SlideInAnimation,
        SlideOutAnimation,
        GrowInAnimation,
        GrowOutAnimation,
        trigger('valueAnimation', [
            transition(
                ':increment',
                group([
                    query(':enter', [
                        style({ transform: 'scale(0)' }),
                        animate(
                            '0.8s ease-out',
                            style({
                                transform: 'scale(1)',
                            })
                        ),
                    ]),
                ])
            ),
            transition(
                ':decrement',
                group([
                    query(':enter', [
                        style({ transform: 'scale(0)' }),
                        animate(
                            '0.8s ease-out',
                            style({
                                transform: 'scale(1)',
                            })
                        ),
                    ]),
                ])
            ),
        ]),
    ],
})
export class DepositAuthorsComponent implements OnInit, OnDestroy {
    @Input() isOrganizationButtonDisplay = true;
    @Input() authors: UntypedFormArray;
    @ViewChild('searchInput', { static: false, read: MatAutocompleteTrigger })
    searchInput: MatAutocompleteTrigger;

    enterNewPerson = false;
    noValuesFound = false;
    searchInProgress = false;
    creatorsAndContributors: User[] = [];
    newAuthorType: 'person' | 'organization' = undefined;

    private authorDestroy = new Subject<void>();
    authorCtrl = new UntypedFormControl();
    private _formOpenedBy: MatButton;

    constructor(
        private depositAuthorsService: DepositAuthorsService,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.listenToSearchInput();
    }

    listenToSearchInput() {
        this.authorCtrl.valueChanges
            .pipe(
                debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
                distinctUntilChanged(),
                takeUntil(this.authorDestroy)
            )
            .subscribe((value) => {
                this.noValuesFound = false;
                if (value && value.length >= DepositForm.MIN_INPUT_LENGTH) {
                    this.searchInProgress = true;
                    this.getCreatorsContributors(value);
                }
            });
    }

    getCreatorsContributors(value) {
        this.depositAuthorsService.getCreatorsContributors(value).subscribe(
            (data) => {
                this.searchInProgress = false;
                if (data) {
                    this.creatorsAndContributors = data as User[];
                } else {
                    this.noDataFound();
                }
            },
            (error) => {
                this.searchInProgress = false;
                this.noDataFound();
            }
        );
    }

    noDataFound() {
        this.noValuesFound = true;
        this.creatorsAndContributors = [];
    }

    clearInput() {
        setTimeout(() => {
            this.authorCtrl.reset();
        }, 0);
        this.searchInput.closePanel();
    }

    onAuthorSelect(author) {
        this.creatorsAndContributors = [];
        if (!this.isAuthorExists(author.id)) {
            this.addAuthor(author);
            this.depositAuthorsService.addUserId(author.id);
        }
        this.clearInput();
    }

    isAuthorExists(id) {
        const authorExist = this.authors.value.some(
            (author) => author.id === id && author.role === ''
        );
        const exist = this.depositAuthorsService.isUserExist(id);
        return authorExist || exist;
    }

    addAuthor(author: User) {
        const addedAuthor = new User();
        addedAuthor.setName(author.actualName ?? author.name);
        addedAuthor.setId(author.id);
        addedAuthor.setOrcid(author.orcid);
        addedAuthor.setAffiliationName(author.affiliationName);
        addedAuthor.setAffiliationCode(author.affiliationCode);
        addedAuthor.setRole('');
        addedAuthor.setOwner(false);
        addedAuthor.setIsAffiliated(author.isAffiliated);
        addedAuthor.setIsDisplayInPublicProfile(author.isAffiliated);
        addedAuthor.setStatus(EsploroCreatorStatus.MANUAL);
        addedAuthor.setSource(author.source ?? 'person');
        this.authors?.push(this.formBuilder.group(addedAuthor));
    }

    onSearchInputFocus() {
        setTimeout(() => {
            this.searchInput.openPanel();
        }, 0);
    }

    toggleNewAuthorForm(type, button: MatButton) {
        this.enterNewPerson = true;
        this.newAuthorType = type;
        this._formOpenedBy = button;
    }

    ngOnDestroy() {
        this.depositAuthorsService.existsUsersList = [];
        if (!this.authorDestroy.isStopped) {
            this.authorDestroy.next();
            //unsubscribe from the subject itself
            this.authorDestroy.unsubscribe();
        }
    }

    handleFormClose() {
        this.enterNewPerson = false;
        setTimeout(() => {
            if (this._formOpenedBy !== undefined) {
                this._formOpenedBy.focus();
                this._formOpenedBy = undefined;
            }
        });
    }
}
