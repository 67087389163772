import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabComponentModel } from '../../../shared/interfaces/tab-component.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DepositFormService } from '../../deposit-form.service';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { JwtUtilService } from '../../../core/jwt-util.service';

@Component({
    selector: 'esp-deposit-step-one',
    templateUrl: './deposit-step-one.component.html',
    styleUrls: ['./deposit-step-one.component.scss'],
    animations: [GrowInAnimation, GrowOutAnimation],
})
export class DepositStepOneComponent implements OnInit {
    @Input() tab1;
    @Input() jumpToFiles;
    @Output() assetTypeSelectedEmitter: EventEmitter<boolean> =
        new EventEmitter();
    @Output() assetTypeClearedEmitter: EventEmitter<boolean> =
        new EventEmitter();
    @Output() closeDeposit: EventEmitter<any> = new EventEmitter();

    config: any;
    showOnboarding = true;
    allowMultipleFiles = true;
    selectedResearch = null;
    isTypeSelected = false;
    userId: string;

    constructor(
        private depositFormService: DepositFormService,
        private depositFormDataService: DepositFormDataService,
        private jwtUtilService: JwtUtilService
    ) {
        this.userId = this.jwtUtilService.getUserID();
    }

    ngOnInit() {
        if (this.depositFormDataService.isDraft) {
            this.showOnboarding = false;
        }
    }

    displaySearchFor(comp?: TabComponentModel) {
        return !this.depositFormDataService.isReservedOrRegisteredDoi();
    }

    displayFileUploader(comp: TabComponentModel) {
        return comp.field === 'file_uploader';
    }

    onResearchSelection(event) {
        this.selectedResearch = event;
    }

    onRemoveSelectedWork() {
        this.selectedResearch = '';
        this.depositFormDataService.enableFormEnrichment();
    }

    isRequired(comp) {
        return comp.mandatory;
    }

    onTypeSelection() {
        this.isTypeSelected = true;
        this.assetTypeSelectedEmitter.emit();
    }

    onClearSelection() {
        this.isTypeSelected = false;
        this.assetTypeClearedEmitter.emit();
    }

    getSearchForTitle(component?) {
        if (!this.selectedResearch && this.displaySearchFor(component)) {
            return 'research.search.title';
        } else if (this.selectedResearch && this.displaySearchFor(component)) {
            return 'research.identify.title';
        }
    }

    getSearchForDescription(component?) {
        if (!this.selectedResearch && this.displaySearchFor(component)) {
            return 'research.search.subtitle';
        } else if (this.selectedResearch && this.displaySearchFor(component)) {
            return 'research.search.selected.asset.subtitle';
        }
    }
}
