import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { PortalEsploroSocialMediaLinksConfiguration } from '../shared/interfaces/social-media-links-configuration.interface';
// eslint-disable-next-line max-len
import { PortalEsploroFooterLinksConfiguration } from '../shared/interfaces/view-configuration/portal-esploro-links-configuration.interface';
import { UrlUtils } from '../shared/utils/url.utils';
import { MediaService } from '@exl-ng/mulo-core';
import { MatCssAdditionalPaletteContrastNames } from '@exl-ng/mulo-common';
import { CustomizationService } from '../core/customization.service';
import { LinkResolverService } from '../core/link-resolver.service';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { BrandService } from '../core/brand.service';
import { AriaLivePoliteness } from '@angular/cdk/a11y';

@Component({
    selector: 'esp-esploro-footer',
    templateUrl: './esploro-footer.component.html',
    styleUrls: ['./esploro-footer.component.scss'],
})
export class EsploroFooterComponent implements OnInit, AfterViewInit {
    footerLinks = [];

    socialMediaLinks: PortalEsploroSocialMediaLinksConfiguration[] = [];

    institutionCode: string;
    institutionName: string;
    logoEsploro: string;
    footerLogo: AriaLivePoliteness;
    esploroLogo: AriaLivePoliteness;
    modeTheme: 'light' | 'dark';
    logoUrl$;
    isProfileActivate: boolean;
    showPoweredBy = true;

    ExlibrisLink =
        'https://exlibrisgroup.com/products/esploro-research-services-platform/';

    socialMediaPortal: Portal<any>;
    copyRightsPortal: Portal<any>;

    @ViewChild('templateSocialMedia') templateSocialMedia: TemplatePortal<any>;
    @ViewChild('templateCopyRights') templateCopyRights: TemplatePortal<any>;

    constructor(
        private configSvc: ConfigurationHandlerService,
        public media: MediaService,
        private customizationService: CustomizationService,
        private linkResolverService: LinkResolverService,
        private brandService: BrandService
    ) {
        this.institutionCode = UrlUtils.getParam('institution');
        this.institutionName = configSvc.getMainInstitutionName();
        this.showPoweredBy =
            configSvc.getCustomerParameter('hide_esploro_logo') === undefined ||
            !configSvc.isCustomerParameterEnabled('hide_esploro_logo');
    }

    ngOnInit() {
        this.handleFooterLinks();
        this.handleFooterSocialMediaLinks();
        this.setLogoTheme();
        this.logoUrl$ = this.brandService.logoPathUrl$;
        this.isProfileActivate = this.configSvc.isProfileActivate();
    }

    ngAfterViewInit() {
        this.socialMediaPortal = this.templateSocialMedia;
        this.copyRightsPortal = this.templateCopyRights;
    }

    handleFooterLinks() {
        let links = this.configSvc.getPortalConfigurationFooterLinks() || [];
        const arrLinks = [];
        let index = -1;
        links = links.filter((item) => item.active === true);
        links.forEach((item: PortalEsploroFooterLinksConfiguration, _index) => {
            if (item.type === 'entry') {
                item.url = this.linkResolverService.getLinkUrl(item.url);
            }
            if (
                item.type === 'head' ||
                (_index === 0 && item.type === 'entry')
            ) {
                //In case first item is entry
                if (item.type === 'head') {
                    arrLinks.push({ headline: item, arr: [] });
                } else {
                    arrLinks.push({ headline: null, arr: [item] });
                }
                index++;
            } else {
                arrLinks[index].arr.push(item);
            }
        });
        this.footerLinks = arrLinks;
    }

    get hasLinks() {
        return this.footerLinks.length > 0;
    }

    get displayLogo() {
        return this.configSvc.getPortalConfigurationFooterIncludeLogo();
    }

    get displayCopyrights() {
        return this.configSvc.getPortalConfigurationFooterIncludeCopyrights();
    }

    handleFooterSocialMediaLinks() {
        let socials = this.configSvc.getPortalConfigurationSocialMediaLinks();
        socials = socials.filter((item) => item.active === true);
        socials.forEach(
            (social: PortalEsploroSocialMediaLinksConfiguration) => {
                social.url = this.linkResolverService.updateSocialMediaLinkUrl(
                    social.url,
                    social.code.toLowerCase()
                );
            }
        );
        this.socialMediaLinks = socials;
    }

    setLogoTheme() {
        const contrast = this.customizationService.getThemeByConfig('footer');
        if (contrast === MatCssAdditionalPaletteContrastNames.dark) {
            this.logoEsploro = 'assets/images/esploro-logo.png';
            this.modeTheme = 'light';
        } else {
            this.modeTheme = 'dark';
            this.logoEsploro = 'assets/images/esploro-white-logo.png';
        }
    }
}
