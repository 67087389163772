import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ExternalOrganizationFieldComponent } from './external-organization-field.component';


@NgModule({
    declarations: [ExternalOrganizationFieldComponent],
    imports: [
        SharedModule,
    ],
    exports: [ExternalOrganizationFieldComponent],
})
export class ExternalOrganizationFieldModule {}
