import { Routes } from '@angular/router';
import { AuthGuard } from './core/auth-guard.service';
import { IndexPageComponent } from './index-page/index-page.component';
import { LoginComponent } from './login/login.component';
import { LoginAsComponent } from './login/login-as/login-as.component';
import { LoginErrorNoResearcherRoleComponent } from './login/login-error-no-researcher-role/login-error-no-researcher-role.component';
import { EditionGuard } from './core/edition-guard.service';
import { ProfileGuard } from './core/profile-guard.service';
import { EntityEditableGuard } from './core/entity-editable-guard.service';
import { ResearchUnitsGuard } from './core/research-units-guard.service';
import { ApcGuard } from './core/apc-guard.service';
import { PivotGuard } from './core/pivot-guard.service';

export const routes: Routes = [
    {
        path: 'general/:page-code',
        loadChildren: () =>
            import('./generic-page/generic-page.module').then(
                (m) => m.GenericPageModule,
            ),
        data: { routeIdx: 4 },
    },
    {
        path: 'profile',
        loadChildren: () =>
            import(
                './profile/profile-standalone/profile-standalone.module'
            ).then((m) => m.ProfileStandaloneModule),
        canActivate: [AuthGuard],
        data: { routeIdx: 3 },
    },
    {
        path: 'profiles/:idx',
        component: IndexPageComponent,
        data: { indexKey: 'researchersIndex' },
    },

    {
        path: 'profiles',
        redirectTo: 'profiles/A',
    },
    {
        path: 'output/:idx',
        component: IndexPageComponent,
        data: { indexKey: 'assetsIndex' },
    },
    {
        path: 'output',
        redirectTo: 'output/A',
    },
    {
        path: 'deposit',
        loadChildren: () =>
            import('./student/student-deposit/student-deposit.module').then(
                (m) => m.StudentDepositModule,
            ),
        canActivate: [AuthGuard],
        data: { role: 'User' },
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { routeIdx: 0 },
    },
    {
        path: 'login-as',
        component: LoginAsComponent,
        canActivate: [AuthGuard],
        data: { routeIdx: 1 },
    },
    {
        path: 'login-error',
        component: LoginErrorNoResearcherRoleComponent,
    },
    {
        path: 'settings',
        loadChildren: () =>
            import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuard],
        data: { routeIdx: 10 },
    },
    {
        path: 'search',
        loadChildren: () =>
            import('./discovery/discovery.module').then(
                (m) => m.DiscoveryModule,
            ),
        data: { routeIdx: 'search' },
    },
    {
        path: 'profile/:id',
        canActivate: [EditionGuard, ProfileGuard],
        loadChildren: () =>
            import(
                './profile/profile-standalone/profile-standalone.module'
            ).then((m) => m.ProfileStandaloneModule),
    },
    {
        path: 'outputs',
        loadChildren: () =>
            import('./asset-page/asset-page.module').then(
                (m) => m.AssetPageModule,
            ),
    },
    {
        path: 'project',
        canActivate: [EntityEditableGuard],
        loadChildren: () =>
            import('./project/project-page/project-page.module').then(
                (m) => m.ProjectPageModule,
            ),
    },
    {
        path: 'activity',
        canActivate: [EntityEditableGuard],
        loadChildren: () =>
            import('./activities/activities-page/activities-page.module').then(
                (m) => m.ActivitiesPageModule,
            ),
    },
    {
        path: 'resource',
        canActivate: [EntityEditableGuard],
        loadChildren: () =>
            import('./resources/resource-page/resource-page.module').then(
                (m) => m.ResourcePageModule,
            ),
    },
    {
        path: 'research-units',
        canActivate: [ResearchUnitsGuard],
        loadChildren: () =>
            import('./research-units/research-units.module').then(
                (m) => m.ResearchUnitsModule,
            ),
    },
    {
        path: 'apc',
        canActivate: [AuthGuard],
        canLoad: [ApcGuard],
        loadChildren: () =>
            import('./apc-form-standalone/apc-form-standalone.module').then(
                (m) => m.ApcFormStandaloneModule,
            ),
    },
    {
        path: 'funding',
        canActivate: [AuthGuard],
        canLoad: [PivotGuard],
        loadChildren: () =>
            import('./pivot-standalone/pivot-standalone.module').then(
                (m) => m.PivotStandaloneModule,
            ),
    },
    {
        path: 'collections',
        loadChildren: () =>
            import('./collections/collections.module').then(
                (m) => m.CollectionsModule,
            ),
    },
    {
        path: 'resources',
        loadChildren: () =>
            import('./resources/resources.module').then(
                (m) => m.ResourcesModule,
            ),
    },
    {
        path: '',
        loadChildren: () =>
            import('./portal/portal.module').then((m) => m.PortalModule),
    },
    { path: '**', redirectTo: 'search' },
];
