import {UntypedFormGroup} from '@angular/forms';
import {ResourceTypeUtils} from '../utils/resource-type.utils';

export function validateSpatialMandatoryFields(group: UntypedFormGroup) {
    const resourceType = group.get('type').value;
    if ((ResourceTypeUtils.isDataset(resourceType))) {
        if (group.get('enableAddress').value && !group.get('address').value){
            return { validateSpatialMandatoryFields: true };
        }
        if (group.get('enablePoint').value && (!group.get('pointLatitude').value || !group.get('pointLongitude').value)){
            return { validateSpatialMandatoryFields: true };
        }
        if (group.get('enableBoundingBox').value && (!group.get('boundingBoxSouth').value || !group.get('boundingBoxWest').value
        || !group.get('boundingBoxNorth').value || !group.get('boundingBoxEast').value)){
            return { validateSpatialMandatoryFields: true };
        }
    }
    return null;
}
