import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    HostListener,
} from '@angular/core';
import { User } from '../../../../shared/interfaces/user.model';
import { DepositAuthorsService } from '../deposit-authors.service';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    FormGroupDirective,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { StringUtils } from '../../../../shared/utils/string.utils';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { AnnouncerService } from '../../../../core/announcer.service';
import { EsploroCreatorStatus } from '../../../../shared/interfaces/esploro-author.interface';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'esp-new-author',
    templateUrl: './new-author.component.html',
    styleUrls: ['./new-author.component.scss'],
    host: { class: 'esp-new-author' },
    animations: [GrowInAnimation, GrowOutAnimation],
})
export class NewAuthorComponent implements OnInit, OnDestroy {
    @ViewChild('last', { static: false }) last: ElementRef;
    @ViewChild('first', { static: false }) first: ElementRef;
    @ViewChild('formDirective', { static: false })
    formDirective: FormGroupDirective;

    @Input() enterNewPerson: boolean;
    @Input() newAuthorType;
    @Output() enterNewPersonChange = new EventEmitter();
    @Output() newPerson = new EventEmitter<User>();

    @Input()
    get person() {
        return this._person;
    }
    set person(p: UntypedFormGroup) {
        this._person = p;
    }
    private _person: UntypedFormGroup;
    private affiliationDestroy = new Subject<void>();

    @HostListener('keydown.Escape', ['$event']) private keyFunc(event) {
        event.stopPropagation();
        this.close();
    }

    constructor(
        private depositAuthorsService: DepositAuthorsService,
        public confirmDialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private announcer: AnnouncerService
    ) {
        this.focusOnNameField();
        this.person = this.formBuilder.group({
            lastName: ['', Validators.required],
            firstName: ['', Validators.required],
            middleName: '',
            nameSuffix: '',
            affiliationCode: '',
            affiliationName: ['', this.validateInstitution],
        });
    }

    ngOnInit() {}

    close() {
        this.resetForm();
        this.enterNewPerson = false;
        this.enterNewPersonChange.emit(this.enterNewPerson);
    }

    resetForm() {
        this.depositAuthorsService.lastChosenAffiliation = '';
        this.formDirective.resetForm();
        this.person.reset();
    }

    onSubmitNewPerson() {
        this.announcer.annonuceAdd(this.firstName, this.lastName);

        if (this.newAuthorType === 'person') {
            this.addNonAffiliatedResearcherUser();
        } else {
            this.addCreatorGroupAuthor();
        }
    }

    addCreatorGroupAuthor() {
        const addedAuthor = new User();
        addedAuthor.setName(this.firstName);
        addedAuthor.setSource('groupAuthor');
        addedAuthor.setRole('Institution');
        this.newPerson.emit(addedAuthor);
        this.resetForm();
        this.focusOnNameField();
    }

    addNonAffiliatedResearcherUser() {
        this.depositAuthorsService
            .addNonAffiliatedResearcherUser(
                this.lastName,
                this.firstName,
                this.middleName,
                this.nameSuffix,
                this.affiliationCode,
                this.affiliationName
            )
            .subscribe(
                (data) => {
                    this.successAfterCreateNewResearcherUser(data);
                },
                (error) => {}
            );
    }

    successAfterCreateNewResearcherUser(data) {
        const name = [
            this.firstName,
            this.middleName,
            this.lastName,
            this.nameSuffix,
        ]
            .filter(Boolean)
            .join(' ');
        const addedAuthor = new User();
        addedAuthor.setId(data);
        addedAuthor.setName(StringUtils.capitalizeName(name));
        addedAuthor.setAffiliationCode(
            this.affiliationCode ? this.affiliationCode : ''
        );
        addedAuthor.setAffiliationName(
            this.affiliationName ? this.affiliationName : ''
        );
        addedAuthor.setOwner(false);
        addedAuthor.setRole('');
        addedAuthor.setIsAffiliated(false);
        addedAuthor.setIsDisplayInPublicProfile(false);
        addedAuthor.setStatus(EsploroCreatorStatus.MANUAL);
        this.newPerson.emit(addedAuthor);
        this.resetForm();
        this.focusOnNameField();
    }

    focusOnNameField() {
        setTimeout(() => {
            this.first.nativeElement.focus();
        }, 250);
    }

    isValid() {
        if (
            this.newAuthorType === 'person' &&
            this.person.status === 'VALID' &&
            (!this.affiliationName || this.affiliationCode)
        ) {
            return true;
        } else if (
            this.newAuthorType !== 'person' &&
            this.person.get('firstName').status === 'VALID'
        ) {
            return true;
        }
        return false;
    }

    validateInstitution: ValidationErrors | null = () => {
        const institutionValidator =
            !this.affiliationName || this.affiliationCode;
        return institutionValidator
            ? null
            : {
                  validateInstitution: {
                      valid: false,
                  },
              };
    };

    get lastName() {
        return this.person?.get('lastName').value;
    }

    get firstName() {
        return this.person?.get('firstName').value;
    }

    get middleName() {
        return this.person?.get('middleName').value;
    }

    get nameSuffix() {
        return this.person?.get('nameSuffix').value;
    }

    get affiliationName() {
        return this.person?.get('affiliationName').value;
    }

    get affiliationCode() {
        return this.person?.get('affiliationCode').value;
    }

    ngOnDestroy() {
        if (!this.affiliationDestroy.isStopped) {
            this.affiliationDestroy.next();
            //unsubscribe from the subject itself
            this.affiliationDestroy.unsubscribe();
        }
    }
}
