<quill-editor
  [modules]="defaultModule"
  (onContentChanged)="emitChangedContent($event)"
  [theme]="editorTheme"
  [formControl]="editorContent"
  [class.has-sneaky-toolbar]="sneakyToolbar"
  [class.has-content]="editorContent?.value"
  [class.is-disabled]="disabled"
  [class.is-floating-label]="!!placeholder"
  [placeholder]="placeholder"
  [readOnly]="disabled"
  [required]="required"
  (onEditorCreated)="a11yRichText.onEditorCreated($event)"
  #a11yRichText="muloRichTextA11y"
  muloRichTextA11y
  [aria-label]="ariaLabel"
>
</quill-editor>
<span id="ql-error">
  <mat-error
    *ngIf="editorContent?.invalid && (editorContent?.dirty || editorContent?.touched)"
    [innerHTML]="editorContent?.errors"
  >
  </mat-error>
</span>
